<template>
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <v-text-field
            v-model.trim="fields.ExplanatoryNoteNumber"
            outlined
            dense
            label="Шифр"
          />
        </div>
        <div class="col-6">
          <v-text-field
            v-model.number="fields.ExplanatoryNoteYear"
            outlined
            dense
            type="number"
            label="Год"
          />
        </div>
      </div>
      <v-select
        v-model="fields.objectType"
        outlined
        dense
        label="Тип объекта"
        :items="selects.objectType"
        clearable
      />
    </div>
    <div class="col-6">
      <v-text-field
        v-model.trim="fields.name"
        outlined
        dense
        label="Наименование"
      />
      <v-select
        v-model="fields.ConstructionType"
        outlined
        dense
        label="Вид работ"
        :items="selects.ConstructionType"
        clearable
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasicTab',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    fields: {
      ExplanatoryNoteNumber: '',
      ExplanatoryNoteYear: '',
      name: '',
      objectType: '',
      ConstructionType: '',
    },
    selects: {
      objectType: [
        'Объект производственного назначение',
        'Объект непроизводственного назначения',
        'Линейный объект',
      ],
      ConstructionType: [
        'Строительство',
        'Реконструкция',
        'Капитальный ремонт',
        'Снос',
        'Сохранение объекта культурного наследия',
      ],
    },
  }),
  watch: {
    fields: {
      deep: true,
      handler(object) {
        this.$emit('input', object)
      },
    },
  },
  mounted() {
    if (Object.keys(this.value).length) {
      this.fields = this.value
    }
  },
}
</script>
