<template>
  <div>
    <v-textarea
      v-model="fields.UsedAbbreviations"
      rows="3"
      outlined
      dense
      label="Используемые сокращения"
    />
    <v-card outlined>
      <v-card-title>
        Перечень документов по стандартизации, используемых для соблюдения
        требований технических регламентов
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-for="({ key }, i) in fields.UsedNorms"
          :key="key"
          v-model="fields.UsedNorms[i].UsedNorm"
          outlined
          dense
          label="Описание нормативных требований"
          append-icon="mdi-trash-can-outline"
          @click:append="fields.UsedNorms.splice(i, 1)"
        />
        <v-btn
          elevation="0"
          x-large
          @click="fields.UsedNorms.push({ UsedNorm: '', key: Date.now() })"
        >
          <v-icon left>mdi-plus</v-icon>
          Добавить
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AdditionallyTab',
  data: () => ({
    fields: {
      UsedAbbreviations: '',
      UsedNorms: [],
    },
  }),
}
</script>

<style scoped></style>
