<template>
  <v-data-table
    class="custom-table-2 td-no-padding"
    :headers="cols"
    :items="items"
    :mobile-breakpoint="0"
    hide-default-footer
    disable-sort
  >
    <template
      v-for="{ value: key, text } in headers"
      #[`item.${key}`]="{ item }"
    >
      <v-text-field
        :key="key"
        v-model="item[key]"
        :label="!item[key] ? `Введите «${text}»` : null"
        :rules="[rules.required]"
        :disabled="item.isDisabled && item.isDisabled.includes(key)"
        outlined
        dense
        hide-details
      />
    </template>
    <template #item.actions="{ item }">
      <v-btn
        v-if="!item.isDisabled || !item.isDisabled.includes('remove')"
        title="Удалить"
        icon
        color="primary"
        @click="removeRow(item)"
      >
        <v-icon small>mdi-trash-can-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:body.append>
      <tr>
        <td v-for="{ value: key, text } in headers" :key="key">
          <v-text-field
            ref="newRow"
            v-model="newRow[key]"
            :label="`Введите «${text}»`"
            :rules="[rules.required]"
            outlined
            dense
            hide-details
          />
        </td>
        <td style="text-align: center">
          <v-btn title="Добавить" icon color="primary" @click="createNewRow">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import rules from '@/mixins/rules'

export default {
  name: 'TableFields',
  mixins: [rules],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    items: [],
    newRow: {},
  }),
  computed: {
    cols: (vm) => [
      ...vm.headers,
      {
        value: 'actions',
        width: '40px',
        align: 'center',
      },
    ],
  },
  watch: {
    value(value) {
      if (!this.items.includes(value)) {
        this.items = this.value
      }
    },
  },
  created() {
    this.resetNewRow()
  },
  methods: {
    resetNewRow() {
      this.headers.forEach(({ value }) => {
        this.$set(this.newRow, value, '')
      })
    },
    createNewRow() {
      let isEmpty = false
      this.$refs.newRow.forEach((component) => {
        if (!component.$el.querySelector('input').value && !isEmpty) {
          isEmpty = true
          component.focus()
        }
      })

      if (!isEmpty) {
        const row = cloneDeep(this.newRow)
        this.items.push(row)
        this.$emit('createRow', row)
        this.$emit('input', this.items)
        this.resetNewRow()
        this.$refs.newRow.forEach((component) => {
          component.resetValidation()
        })
      }
    },
    removeRow(row) {
      this.items.splice(this.items.indexOf(row), 1)
      this.$emit('removeRow', row)
      this.$emit('input', this.items)
    },
  },
}
</script>

<style lang="scss" scoped>
.td-no-padding::v-deep {
  td {
    padding: 0 !important;
    fieldset {
      border-color: transparent !important;
    }
    .v-input {
      font-size: inherit;
    }
  }
  .v-data-table__empty-wrapper {
    display: none;
  }
}
</style>
