export const FIELDS = {
  Placement: '',
  DangerousAndComplex: '',
  Unique: '',
  Name: '',
  FunctionsClass: [],
  FunctionsNote: '',
  FunctionsClassKSI: [],
  ProductsNote: '',
  FunctionsFeatures: '',
  EnergyEfficiencyClass: '',
  EnergyEfficiencyImproving: '',
  DangerousPlacement: '',
  radioButton: true,
  PowerIndicator: [],
  TEI: [],
}

export const TABLE_COLUMNS = [
  { text: 'Показатель', value: 'text' },
  { text: 'Значение', value: 'value', width: '210px', align: 'center' },
]

export const SELECTS = {
  Placement: [
    { text: 'Объекты, расположенные на суше', value: '1' },
    { text: 'Водный объект', value: '2' },
  ],
  DangerousAndComplex: [
    {
      text: '1) объекты использования атомной энергии (в том числе ядерные установки, пункты хранения ядерных материалов и радиоактивных веществ, пункты хранения радиоактивных отходов) ',
      value: '1',
    },
    {
      text: '2) гидротехнические сооружения первого и второго классов, устанавливаемые в соответствии с законодательством о безопасности гидротехнических сооружений',
      value: '2',
    },
    {
      text: '3) сооружения связи, являющиеся особо опасными, технически сложными в соответствии с законодательством Российской Федерации в области связи',
      value: '3',
    },
    {
      text: '4) линии электропередачи и иные объекты электросетевого хозяйства напряжением 330 киловольт и более',
      value: '4',
    },
    {
      text: '5) объекты космической инфраструктуры',
      value: '5',
    },
    {
      text: '6) объекты инфраструктуры воздушного транспорта, являющиеся особо опасными, технически сложными объектами в соответствии с воздушным законодательством Российской Федерации',
      value: '6',
    },
    {
      text: '7) объекты капитального строительства инфраструктуры железнодорожного транспорта общего пользования, являющиеся особо опасными, технически сложными объектами в соответствии с законодательством Российской Федерации о железнодорожном транспорте',
      value: '7',
    },
    {
      text: '8) объекты инфраструктуры внеуличного транспорта',
      value: '8',
    },
    {
      text: '9) портовые гидротехнические сооружения, относящиеся к объектам инфраструктуры морского порта, за исключением объектов инфраструктуры морского порта, предназначенных для стоянок и обслуживания маломерных, спортивных парусных и прогулочных судов',
      value: '9',
    },
    {
      text: '10.1) тепловые электростанции мощностью 150 мегаватт и выше',
      value: '10.1',
    },
    {
      text: '10.2) подвесные канатные дороги',
      value: '10.2',
    },
    {
      text: '11а) опасные производственные объекты I и II классов опасности, на которых получаются, используются, перерабатываются, образуются, хранятся, транспортируются, уничтожаются опасные вещества',
      value: '11а',
    },
    {
      text: '11б) опасные производственные объекты, на которых получаются, транспортируются, используются расплавы черных и цветных металлов, сплавы на основе этих расплавов с применением оборудования, рассчитанного на максимальное количество расплава 500 килограммов и более',
      value: '11б',
    },
    {
      text: '11в) опасные производственные объекты, на которых ведутся горные работы (за исключением добычи общераспространенных полезных ископаемых и разработки россыпных месторождений полезных ископаемых, осуществляемых открытым способом без применения взрывных работ), работы по обогащению полезных ископаемых',
      value: '11в',
    },
  ],
  Unique: [
    {
      text: '1) высота более чем 100 метров, для ветроэнергетических установок - более чем 250 метров',
      value: '1',
    },
    {
      text: '2) пролеты более чем 100 метров',
      value: '2',
    },
    {
      text: '3) наличие консоли более чем 20 метров',
      value: '3',
    },
    {
      text: '4) заглубление подземной части (полностью или частично) ниже планировочной отметки земли более чем на 15 метров',
      value: '4',
    },
  ],
  EnergyEfficiencyClass: ['A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'],
}
