<template>
  <div>
    <v-select
      v-model="fields.Placement"
      outlined
      dense
      clearable
      label="Вид места расположения объекта"
      :items="selects.Placement"
    />
    <v-select
      v-model="fields.DangerousAndComplex"
      outlined
      dense
      clearable
      label="Особо опасные и технически сложные объекты"
      :items="selects.DangerousAndComplex"
    />
    <v-select
      v-model="fields.Unique"
      outlined
      dense
      clearable
      label="Особо опасные и технически сложные объекты"
      :items="selects.Unique"
    />
    <v-textarea
      v-model="fields.Name"
      rows="3"
      outlined
      dense
      label="Наименование объекта"
    />
    <TreeSelect
      ref="FunctionsClass"
      v-model="fields.FunctionsClass"
      title="Функциональное назначение объекта по классификатору"
      :items="itemsFunctionsClass"
      @input="changeFunctionsClass"
    >
      <template #label="{ item }">
        <template v-if="item.appointment">
          <div class="tree-name">
            {{ item.name.replace(` - ${item.code}`, '') }}
          </div>
          <div class="tree-code">{{ item.code }}</div>
        </template>
        <template v-else>{{ item.name }}</template>
      </template>
    </TreeSelect>
    <v-textarea
      v-model="fields.FunctionsNote"
      rows="3"
      outlined
      dense
      label="Описание функционального назначения"
    />
    <TreeSelect
      v-model="fields.FunctionsClassKSI"
      title="Функциональное назначение объекта по КСИ"
      :items="FUNCTIONS_CLASS_KSI"
    />
    <v-textarea
      v-model="fields.ProductsNote"
      rows="3"
      outlined
      dense
      label="Общие сведения о составе и характеристике производства"
    />
    <v-textarea
      v-model="fields.FunctionsFeatures"
      rows="3"
      outlined
      dense
      label="Принадлежность к объектам транспортной инфраструктуры и к другим объектам, функционально-технологические особенности которых влияют на их безопасность"
    />
    <div class="mt-6">
      <h2 class="mb-6">Проектная мощность</h2>
      <TableFields v-model="fields.PowerIndicator" :headers="tableColumns" />
    </div>
    <div class="mt-12 mb-12">
      <h2 class="mb-6">Технико-экономические показатели</h2>
      <TableFields v-model="fields.TEI" :headers="tableColumns" />
    </div>
    <v-select
      v-model="fields.EnergyEfficiencyClass"
      outlined
      dense
      clearable
      label="Класс энергетической эффективности"
      :items="selects.EnergyEfficiencyClass"
    />
    <v-textarea
      v-model="fields.EnergyEfficiencyImproving"
      rows="3"
      outlined
      dense
      label="Сведения о повышении энергетической эффективности"
    />
    <v-textarea
      v-model="fields.DangerousPlacement"
      rows="3"
      outlined
      dense
      label="Принадлежность к опасным производственным объектам"
    />
    <div>
      <h2>Здания (сооружения), входящие в состав сложного объекта</h2>
      <v-radio-group v-model="fields.radioButton" row>
        <v-radio label="Да" :value="true"></v-radio>
        <v-radio label="Нет" :value="false"></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

import TreeSelect from '../components/TreeSelect'
import { FIELDS, SELECTS, TABLE_COLUMNS } from '../constants/OBJECT_TAB'
import FUNCTIONS_CLASS_KSI from '../constants/FUNCTIONS_CLASS_KSI'
import TableFields from '@/views/ProjectItem/PZ/components/TableFields'

export default {
  name: 'ObjectTab',
  components: { TableFields, TreeSelect },
  data: () => ({
    fields: cloneDeep(FIELDS),
    selects: SELECTS,
    tableColumns: TABLE_COLUMNS,
    FUNCTIONS_CLASS_KSI,
    itemsFunctionsClass: [],
  }),
  watch: {
    fields: {
      deep: true,
      handler(value) {
        this.$emit('input', { IndustrialObject: value })
      },
    },
  },
  async mounted() {
    const resp = await fetch('/json/FUNCTIONS_CLASS.json')
    const json = await resp.json()
    this.itemsFunctionsClass = json
  },
  methods: {
    changeFunctionsClass() {
      const teps = []
      this.$refs.FunctionsClass.activeChain.forEach((item) => {
        if (item.teps) {
          teps.push(...item.teps)
        }
      })

      this.fields.TEI = this.fields.TEI.filter((o) => !o.isFunctionsClass)

      if (teps.length) {
        this.fields.TEI.unshift(
          ...teps.map(({ name }) => ({
            text: name,
            value: '',
            isDisabled: ['text', 'remove'],
            isFunctionsClass: true,
          }))
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tree-name,
.tree-code {
  line-height: 1.4;
}

.tree-code {
  font-size: 80%;
  color: rgba(0, 0, 0, 0.65);
}
</style>
