<template>
  <div v-if="!isLoading">
    <Back>Назад</Back>
    <h1>Пояснительная записка</h1>
    <div class="caption mb-2">Проект: {{ project.name }}</div>
    <v-tabs ref="tabs" v-model="tab" class="tabs">
      <v-tab>Основное</v-tab>
      <v-tab>Заказчик</v-tab>
      <v-tab>Исполнители</v-tab>
      <v-tab>Объект</v-tab>
      <v-tab>Земельный участок</v-tab>
      <v-tab>Документация</v-tab>
      <v-tab>Дополнительно</v-tab>
      <v-tab>Атомные станции</v-tab>
    </v-tabs>
    <v-divider />

    <v-tabs-items v-model="tab" class="tabs pt-4">
      <v-tab-item>
        <BasicTab v-model="model.basic" />
      </v-tab-item>
      <v-tab-item>
        <CustomerTab v-model="model.customer" />
      </v-tab-item>
      <v-tab-item>
        <PerformersTab v-model="model.performers" />
      </v-tab-item>
      <v-tab-item>
        <ObjectTab v-model="model.object" />
      </v-tab-item>
      <v-tab-item>
        <!-- Земельный участок -->
      </v-tab-item>
      <v-tab-item>
        <!-- Документация -->
      </v-tab-item>
      <v-tab-item>
        <AdditionallyTab v-model="model.additionally" />
      </v-tab-item>
      <v-tab-item>
        <!-- Атомные станции -->
      </v-tab-item>
    </v-tabs-items>
  </div>
  <v-progress-linear v-else class="loader" color="primary" indeterminate />
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Back from '@/components/Back'
import BasicTab from './partials/BasicTab'
import CustomerTab from './partials/CustomerTab'
import PerformersTab from './partials/PerformersTab'
import ObjectTab from './partials/ObjectTab'
import AdditionallyTab from './partials/AdditionallyTab'

export default {
  name: 'PZ',
  components: {
    Back,
    BasicTab,
    CustomerTab,
    PerformersTab,
    ObjectTab,
    AdditionallyTab,
  },
  data: () => ({
    isLoading: false,
    tab: 0,
    model: {
      basic: {},
      customer: {},
      performers: {},
      object: {},
      additionally: {},
    },
  }),
  computed: {
    ...mapState('projects', ['project']),
    projectId: (vm) => vm.$route.params.projectId,
  },
  // watch: {
  //   model: {
  //     deep: true,
  //     handler(value) {
  //       console.log('model', value)
  //     },
  //   },
  // },
  async mounted() {
    this.isLoading = true
    await this.fetchProject(this.projectId)
    this.isLoading = false
  },
  methods: {
    ...mapActions('projects', ['fetchProject']),
  },
}
</script>

<style lang="scss" scoped>
.tabs .v-tab {
  background: none !important;
}
</style>
