<template>
  <v-card class="card-hover" outlined>
    <v-card-text>
      <div class="row">
        <div
          class="col-4 pb-0 position-relative"
          :class="{ 'mb-3': !orgPersonIP }"
        >
          <v-select
            v-model="orgPersonIP"
            outlined
            dense
            clearable
            label="Организационно-правовая форма *"
            :items="LEGAL_ENTITY_AND_INDIVIDUAL"
            :rules="[rules.required]"
            @change="input"
          />
          <v-btn class="remove" color="primary" icon @click="$emit('remove')">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-if="orgPersonIP" class="row mt-0">
        <div v-if="isPerson" class="col-4 pb-0">
          <v-text-field
            v-model="fields.SNILS"
            outlined
            dense
            label="СНИЛС *"
            :rules="[rules.required]"
          />
        </div>
        <div
          v-if="isOrganization || isIP || isForeignOrganization"
          class="col-4 pb-0"
        >
          <v-text-field
            v-model="fields.OrgINN"
            outlined
            dense
            label="ИНН *"
            :rules="[rules.required]"
          />
        </div>
        <div v-if="isOrganization || isForeignOrganization" class="col-4 pb-0">
          <v-text-field
            v-model="fields.OrgKPP"
            outlined
            dense
            label="КПП *"
            :rules="[rules.required]"
          />
        </div>
        <div v-if="isOrganization" class="col-4 pb-0">
          <v-text-field
            v-model="fields.OrgOGRN"
            outlined
            dense
            label="ОГРН *"
            :rules="[rules.required]"
          />
        </div>
        <div v-if="isIP" class="col-4 pb-0">
          <v-text-field
            v-model="fields.OGRNIP"
            outlined
            dense
            label="ОГРНИП *"
            :rules="[rules.required]"
          />
        </div>
      </div>
      <div v-if="isOrganization || isForeignOrganization" class="row mt-0">
        <div class="col-12">
          <v-text-field
            v-model="fields.OrgFullName"
            outlined
            dense
            label="Полное наименование *"
            :rules="[rules.required]"
          />
        </div>
      </div>
      <div v-if="isPerson || isIP" class="row mt-0">
        <div class="col-4">
          <v-text-field
            v-model="fields.FamilyName"
            outlined
            dense
            label="Фамилия *"
            :rules="[rules.required]"
          />
        </div>
        <div class="col-4">
          <v-text-field
            v-model="fields.FirstName"
            outlined
            dense
            label="Имя *"
            :rules="[rules.required]"
          />
        </div>

        <div class="col-4">
          <v-text-field
            v-model="fields.SecondName"
            outlined
            dense
            label="Отчество *"
            :rules="[rules.required]"
          />
        </div>
      </div>
      <v-card v-if="fieldAddressKey" class="card-transparent" outlined>
        <v-card-title class="second-title">Адрес</v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].Country"
                outlined
                dense
                label="Страна"
              />
            </div>
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].Region"
                outlined
                dense
                label="Регион"
              />
            </div>
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].District"
                outlined
                dense
                label="Район"
              />
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].City"
                outlined
                dense
                label="Город"
              />
            </div>
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].Settlement"
                outlined
                dense
                label="Населённый пункт"
              />
            </div>
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].Street"
                outlined
                dense
                label="Улица"
              />
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].Building"
                outlined
                dense
                label="Дом"
              />
            </div>
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].Room"
                outlined
                dense
                label="Помещение"
              />
            </div>
            <div class="col-4 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].PostIndex"
                outlined
                dense
                label="Индекс"
              />
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-12 pb-0">
              <v-text-field
                v-model="fields[fieldAddressKey].Note"
                outlined
                dense
                label="Неформализованное описание адреса"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="row mt-4">
        <div class="col-4 pb-0">
          <v-text-field
            v-model="fields.Email"
            outlined
            dense
            label="Email *"
            :rules="[rules.required]"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

import rules from '@/mixins/rules'
import {
  MULTI_FIELDS,
  ADDRESS_FIELDS,
  LEGAL_ENTITY_AND_INDIVIDUAL,
} from '@/views/ProjectItem/PZ/constants'

export default {
  name: 'MultiFields',
  mixins: [rules],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    fieldAddressKey: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    LEGAL_ENTITY_AND_INDIVIDUAL,
    orgPersonIP: '',
    fields: cloneDeep(MULTI_FIELDS),
  }),
  computed: {
    isPerson: (vm) => vm.orgPersonIP === 'Person',
    isOrganization: (vm) => vm.orgPersonIP === 'organization',
    isIP: (vm) => vm.orgPersonIP === 'IP',
    isForeignOrganization: (vm) => vm.orgPersonIP === 'ForeignOrganization',
  },
  watch: {
    fields: {
      deep: true,
      handler() {
        this.input()
      },
    },
  },
  created() {
    if (this.fieldAddressKey) {
      this.$set(this.fields, this.fieldAddressKey, cloneDeep(ADDRESS_FIELDS))
    }

    const keys = Object.keys(this.value)
    if (keys.length) {
      const [orgPersonIP] = keys
      this.orgPersonIP = orgPersonIP
      this.fields = this.value[orgPersonIP]
    }
  },
  methods: {
    input() {
      if (!this.orgPersonIP) return
      this.$emit('input', { [this.orgPersonIP]: this.fields })
    },
  },
}
</script>

<style scoped lang="scss">
.position-relative {
  position: relative;
}

.remove {
  position: absolute;
  left: 100%;
  top: 13px;
}

.second-title {
  font-size: 16px;
}

.card-hover:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.card-transparent {
  background-color: rgba(0, 0, 0, 0);
}
</style>
