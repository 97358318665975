export const LEGAL_ENTITY_AND_INDIVIDUAL = [
  { text: 'Физическое лицо', value: 'Person' },
  { text: 'Юридическое лицо', value: 'organization' },
  { text: 'Индивидуальный предприниматель', value: 'IP' },
  { text: 'Иностранное юридическое лицо', value: 'ForeignOrganization' },
]

export const MULTI_FIELDS = {
  SNILS: '',
  OrgINN: '',
  OrgKPP: '',
  OrgOGRN: '',
  OGRNIP: '',
  OrgFullName: '',
  FamilyName: '',
  FirstName: '',
  SecondName: '',
  Email: '',
}

export const ADDRESS_FIELDS = {
  Country: '',
  Region: '',
  District: '',
  City: '',
  Settlement: '',
  Street: '',
  Building: '',
  Room: '',
  PostIndex: '',
  Note: '',
}
