<template>
  <div>
    <v-card v-for="{ key, title } in items" :key="key" class="mb-8" outlined>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <MultiFields
          v-for="(_, i) in fields[key]"
          :key="keys[key][i]"
          v-model="fields[key][i]"
          :field-address-key="fieldAddressKey"
          class="mb-4"
          @remove="remove(key, i)"
        />
        <v-btn elevation="0" x-large @click="add(key)">
          <v-icon left>mdi-plus</v-icon>
          Добавить
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import each from 'lodash/each'
import MultiFields from '../components/MultiFields'

export default {
  name: 'MultiBlocks',
  components: { MultiFields },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    /**
     * @description Массив объектов
     * @return [{ key: '', title: '' }]
     * */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * @description Ключ объекта "Адресс" (PostAddress, Address)
     * */
    fieldAddressKey: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    fields: {},
    keys: {},
  }),
  watch: {
    fields: {
      deep: true,
      handler(data) {
        this.$emit('input', data)
      },
    },
  },
  mounted() {
    if (this.items.length) {
      this.items.forEach(({ key }) => {
        this.$set(this.fields, key, [])
        this.$set(this.keys, key, [])
      })
    }

    if (Object.keys(this.value).length) {
      this.fields = this.value
      each(this.fields, (item, type) => {
        item.forEach((_, i) => {
          this.keys[type][i] = Date.now() + i
        })
      })
    }
  },
  methods: {
    add(type) {
      this.keys[type].push(Date.now())
      this.fields[type].push({})
    },
    remove(type, index) {
      this.keys[type].splice(index, 1)
      this.fields[type].splice(index, 1)
    },
  },
}
</script>
