<template>
  <MultiBlocks
    v-model="model"
    :items="blocks"
    field-address-key="PostAddress"
  />
</template>

<script>
import MultiBlocks from '../components/MultiBlocks'

export default {
  name: 'CustomerTab',
  components: { MultiBlocks },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    model: {},
    blocks: [
      { key: 'Developer', title: 'Застройщик' },
      { key: 'TechnicalCustomer', title: 'Технический заказчик' },
    ],
  }),
  watch: {
    model: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    if (Object.keys(this.value).length) {
      this.model = this.value
    }
  },
}
</script>
