const array = [
  {
    class: 'A',
    title:
      'Объекты сельского и лесного хозяйства, охоты, рыболовства и рыбоводства',
  },
  {
    subCLass: 'AA',
    title: 'Объекты животноводства',
  },
  {
    subCLass2: 'AAA',
    title: 'Объекты коневодства и верблюдоводства',
  },
  {
    subCLass3: 'AAAA',
    title: 'Здание (сооружение) объектов содержания лошадей (верблюдов)',
  },
  {
    subCLass3: 'AAAB',
    title: 'Здание (сооружение) манежа',
  },
  {
    subCLass3: 'AAAC',
    title:
      'Здание (сооружение) вспомогательных объектов коневодства и верблюдоводства',
  },
  {
    subCLass2: 'AAB',
    title: 'Объекты звероводства и кролиководства',
  },
  {
    subCLass3: 'AABA',
    title: 'Здание (сооружение) для содержания зверей (кроликов)',
  },
  {
    subCLass3: 'AABB',
    title:
      'Здание (сооружение) вспомогательных объектов звероводства и кролиководства',
  },
  {
    subCLass2: 'AAC',
    title: 'Объекты овцеводства и козоводства',
  },
  {
    subCLass3: 'AACA',
    title: 'Здание (сооружение) объекта содержания овец и коз',
  },
  {
    subCLass3: 'AACB',
    title:
      'Здание (сооружение) вспомогательных объектов овцеводства и козоводства',
  },
  {
    subCLass2: 'AAD',
    title:
      'Объекты по выращиванию крупного рогатого скота, производству мяса, молока, выращиванию ремонтного молодняка',
  },
  {
    subCLass3: 'AADA',
    title: 'Здание (сооружение) содержания крупного рогатого скота',
  },
  {
    subCLass3: 'AADB',
    title:
      'Здание (сооружение) вспомогательных объектов по выращиванию крупного рогатого скота, производству мяса, молока',
  },
  {
    subCLass2: 'AAE',
    title: 'Объекты птицеводства',
  },
  {
    subCLass3: 'AAEA',
    title: 'Здание птицефабрики',
  },
  {
    subCLass3: 'AAEB',
    title: 'Сооружение инкубатория, репродуктора, племенного цеха',
  },
  {
    subCLass3: 'AAEC',
    title: 'Сооружение птичника',
  },
  {
    subCLass3: 'AAED',
    title: 'Здание (сооружение) вспомогательных объектов птицеводства',
  },
  {
    subCLass2: 'AAF',
    title: 'Объекты пчеловодства',
  },
  {
    subCLass2: 'AAG',
    title: 'Объекты свиноводства',
  },
  {
    subCLass3: 'AAGA',
    title: 'Здание (сооружение) объектов содержания свиней',
  },
  {
    subCLass3: 'AAGB',
    title: 'Здание (сооружение) объектов племенного воспроизводства свиней',
  },
  {
    subCLass3: 'AAGC',
    title: 'Здание (сооружение) вспомогательных объектов свиноводства',
  },
  {
    subCLass2: 'AAH',
    title: 'Здание (сооружение) объектов шелкового производства',
  },
  {
    subCLass2: 'AAJ',
    title: 'Здание (сооружение) вспомогательных объектов животноводства',
  },
  {
    subCLass: 'AB',
    title: 'Объекты ветеринарии и агробиологии',
  },
  {
    subCLass2: 'ABA',
    title: 'Здание (сооружение) объектов ветеринарного обслуживания',
  },
  {
    subCLass2: 'ABB',
    title: 'Агробиологическая станция',
  },
  {
    subCLass: 'AC',
    title: 'Объекты лесного хозяйства',
  },
  {
    subCLass2: 'ACA',
    title: 'Здание (сооружение) объектов лесоводства',
  },
  {
    subCLass2: 'ACB',
    title: 'Здание (сооружение) объектов лесопромышленных предприятий',
  },
  {
    subCLass2: 'ACC',
    title: 'Здание (сооружение) объектов ремонта техники лесной промышленности',
  },
  {
    subCLass: 'AD',
    title:
      'Здание (сооружение) объектов производства пушнины, добытой в результате охоты',
  },
  {
    subCLass: 'AE',
    title: 'Объекты рыбоводного хозяйства',
  },
  {
    subCLass2: 'AEA',
    title: 'Здание (сооружение) объектов выращивания морских видов рыб',
  },
  {
    subCLass2: 'AEB',
    title: 'Здание (сооружение) объектов пресноводного рыбоводного хозяйства',
  },
  {
    subCLass: 'AF',
    title: 'Сооружения объектов мелиорации',
  },
  {
    subCLass2: 'AFA',
    title: 'Сооружение инженерной инфраструктуры в мелиорации',
  },
  {
    subCLass2: 'AFB',
    title: 'Сооружение системы орошения, осушения',
  },
  {
    subCLass: 'AG',
    title: 'Объекты растениеводства',
  },
  {
    subCLass2: 'AGA',
    title: 'Здание (сооружение) объектов выращивания винограда',
  },
  {
    subCLass2: 'AGB',
    title: 'Здание (сооружение) объектов выращивания хмеля',
  },
  {
    subCLass2: 'AGC',
    title: 'Здание (сооружение) объектов обработки и хранения зерна и семян',
  },
  {
    subCLass2: 'AGD',
    title: 'Здание (сооружение) объектов хлопкового растениеводства',
  },
  {
    subCLass2: 'AGE',
    title: 'Здание (сооружение) объектов производства рассады',
  },
  {
    subCLass2: 'AGF',
    title: 'Здание (сооружение) объектов тепличных комбинатов и комплексов',
  },
  {
    subCLass2: 'AGG',
    title: 'Здание (сооружение) объектов садоводческого растениеводства',
  },
  {
    subCLass2: 'AGH',
    title: 'Здание (сооружение) объектов овощехранения',
  },
  {
    subCLass2: 'AGJ',
    title: 'Здание (сооружение) питомников растений',
  },
  {
    subCLass: 'AH',
    title: 'Объекты предприятий биопромышленности',
  },
  {
    subCLass2: 'AHA',
    title: 'Здание (сооружение) объектов по производству вакцин',
  },
  {
    subCLass2: 'AHB',
    title: 'Здание (сооружение) объектов по производству лечебных сывороток',
  },
  {
    subCLass2: 'AHC',
    title:
      'Здание (сооружение) вспомогательных объектов предприятий биопромышленности',
  },
  {
    subCLass: 'AJ',
    title:
      'Объекты вспомогательной инфраструктуры сельского и лесного хозяйства, охоты, рыболовства и рыбоводства',
  },
  {
    subCLass2: 'AJA',
    title: 'Сооружение водопойного пункта',
  },
  {
    subCLass2: 'AJB',
    title: 'Здание (сооружение) моторо-тракторных станций',
  },
  {
    subCLass2: 'AJC',
    title:
      'Здание (сооружение) объектов обезвреживания результатов деятельности сельскохозяйственных предприятий',
  },
  {
    subCLass2: 'AJD',
    title:
      'Здание (сооружение) объектов предприятий ремонта, технического обслуживания и хранения сельскохозяйственной техники',
  },
  {
    class: 'B',
    title: 'Объекты добывающей промышленности',
  },
  {
    subCLass: 'BA',
    title: 'Объекты добычи и обогащения железных руд',
  },
  {
    subCLass2: 'BAA',
    title: 'Здание (сооружение) объектов добычи железных руд открытым способом',
  },
  {
    subCLass2: 'BAB',
    title:
      'Здание (сооружение) объектов добычи железных руд подземным способом',
  },
  {
    subCLass2: 'BAC',
    title: 'Здание (сооружение) объектов обогащения железных руд',
  },
  {
    subCLass2: 'BAD',
    title:
      'Здание (сооружение) вспомогательных объектов при добыче и обогащении железных руд',
  },
  {
    subCLass: 'BB',
    title: 'Объекты добычи и обогащения руд цветных металлов',
  },
  {
    subCLass2: 'BBA',
    title:
      'Здание (сооружение) объектов добычи руд цветных металлов открытым способом',
  },
  {
    subCLass2: 'BBB',
    title:
      'Здание (сооружение) объектов добычи руд цветных металлов закрытым способом',
  },
  {
    subCLass2: 'BBC',
    title: 'Здание (сооружение) объектов обогащения руд цветных металлов',
  },
  {
    subCLass: 'BC',
    title: 'Объекты добычи полезных ископаемых, кроме топливно-энергетических',
  },
  {
    subCLass2: 'BCA',
    title:
      'Здание (сооружение) объектов добычи минерального сырья для химической промышленности и производства минеральных удобрений',
  },
  {
    subCLass2: 'BCB',
    title: 'Здание (сооружение) объектов добычи соли',
  },
  {
    subCLass2: 'BCC',
    title:
      'Здание (сооружение) объектов добычи инертных материалов для строительства',
  },
  {
    subCLass: 'BD',
    title: 'Объекты добычи природного газа',
  },
  {
    subCLass2: 'BDA',
    title: 'Здание (сооружение) объектов добычи природного газа и конденсата',
  },
  {
    subCLass2: 'BDB',
    title: 'Здание (сооружение) объектов переработки газа',
  },
  {
    subCLass3: 'BDBA',
    title: 'Сооружение коллектора трубопровода ',
  },
  {
    subCLass3: 'BDBB',
    title:
      'Сооружение компрессорной станции сжатого воздуха с установкой осушки для нужд КИП ',
  },
  {
    subCLass2: 'BDC',
    title: 'Здание (сооружение) объектов сбора, подготовки и транспорта газа',
  },
  {
    subCLass3: 'BDCA',
    title: 'Сооружение пункта предварительной подготовки газа',
  },
  {
    subCLass2: 'BDD',
    title: 'Здание производственной базы при добыче газа',
  },
  {
    subCLass: 'BE',
    title: 'Объекты добычи сырой нефти',
  },
  {
    subCLass2: 'BEA',
    title: 'Здание (сооружение) объектов добычи сырой нефти из скважин',
  },
  {
    subCLass3: 'BEAA',
    title: 'Нефтенасосная станция (дожимная)',
  },
  {
    subCLass3: 'BEAB',
    title: 'Центральный пункт сбора и подготовки нефти, газа и воды',
  },
  {
    subCLass3: 'BEAC',
    title: 'Компрессорные станция перекачки нефтяного газа',
  },
  {
    subCLass3: 'BEAD',
    title: 'Установка компрессорного газлифта',
  },
  {
    subCLass3: 'BEAE',
    title: 'Измерительная установка нефтяных и газовых производств',
  },
  {
    subCLass3: 'BEAF',
    title: 'Кустовая насосная станция для заводнения нефтяных пластов',
  },
  {
    subCLass2: 'BEB',
    title: 'Здание (сооружение) объектов подготовки нефти, газа и воды',
  },
  {
    subCLass2: 'BEC',
    title: 'Здание (сооружение) объектов поддержания пластового давления',
  },
  {
    subCLass2: 'BED',
    title:
      'Здание (сооружение) объектов сбора и транспорта нефти и нефтяного газа',
  },
  {
    subCLass2: 'BEE',
    title: 'Здание (сооружение) объектов термического воздействия на пласт',
  },
  {
    subCLass2: 'BEF',
    title:
      'Здание (сооружение) объектов производственных баз при добыче нефти и газа',
  },
  {
    subCLass3: 'BEFA',
    title:
      'База производственного обслуживания нефтегазодобывающих предприятий и управлений буровых работ',
  },
  {
    subCLass3: 'BEFB',
    title: 'База материально-технического снабжения нефтяной промышленности',
  },
  {
    subCLass3: 'BEFC',
    title: 'Геофизическая база нефтяной промышленности',
  },
  {
    subCLass: 'BF',
    title: 'Объекты добычи угля и антрацита',
  },
  {
    subCLass2: 'BFA',
    title: 'Здание (сооружение) объектов добычи угля',
  },
  {
    subCLass2: 'BFB',
    title:
      'Здание (сооружение) вспомогательных объектов при добыче и обогащении угля и антрацита',
  },
  {
    subCLass: 'BG',
    title: 'Вспомогательные объекты добывающей промышленности',
  },
  {
    subCLass2: 'BGA',
    title: 'Здание (сооружение) объектов геологоразведки',
  },
  {
    subCLass2: 'BGB',
    title: 'Здание (сооружение) производственно-технических баз',
  },
  {
    class: 'C',
    title: 'Объекты обрабатывающей промышленности',
  },
  {
    subCLass: 'CA',
    title: 'Объекты коксохимической и нефтеперерабатывающей промышленности',
  },
  {
    subCLass2: 'CAA',
    title: 'Здание (сооружение) объектов коксохимической промышленности',
  },
  {
    subCLass2: 'CAB',
    title: 'Объекты нефтеперерабатывающей и нефтехимической промышленности',
  },
  {
    subCLass3: 'CABA',
    title: 'Здание (сооружение) объектов нефтехимического производства для НПЗ',
  },
  {
    subCLass3: 'CABB',
    title:
      'Здание (сооружение) объектов подсобно­производственного, вспомогательного и общезаводского назначения нефтеперерабатывающей промышленности',
  },
  {
    subCLass3: 'CABC',
    title: 'Здание (сооружение) объектов предприятий переработки нефти',
  },
  {
    subCLass3: 'CABD',
    title: 'Здание (сооружение) объектов производства катализаторов',
  },
  {
    subCLass3: 'CABE',
    title: 'Здание (сооружение) объектов производства каучуков и латексов',
  },
  {
    subCLass3: 'CABF',
    title:
      'Здание (сооружение) объектов производства мономеров и продуктов нефтехимического синтеза',
  },
  {
    subCLass3: 'CABG',
    title:
      'Здание (сооружение) объектов производства резиновых технических изделий',
  },
  {
    subCLass3: 'CABH',
    title:
      'Здание (сооружение) объектов производства синтетических жирозаменителей и поверхностно-активных веществ',
  },
  {
    subCLass3: 'CABJ',
    title:
      'Здание (сооружение) объектов производства смазочных масел и присадок',
  },
  {
    subCLass3: 'CABK',
    title:
      'Здание (сооружение) объектов ремонтных служб нефтеперерабатывающей промышленности',
  },
  {
    subCLass2: 'CAC',
    title: 'Объекты угольной промышленности',
  },
  {
    subCLass3: 'CACA',
    title: 'Здание (сооружение) объектов производства изделий из угля',
  },
  {
    subCLass3: 'CACB',
    title: 'Здание (сооружение) объектов углеобогатительного предприятия',
  },
  {
    subCLass: 'CB',
    title: 'Объекты легкой промышленности',
  },
  {
    subCLass2: 'CBA',
    title: 'Здание (сооружение) объектов кожевенной промышленности',
  },
  {
    subCLass2: 'CBB',
    title: 'Здание (сооружение) объектов меховой промышленности',
  },
  {
    subCLass2: 'CBC',
    title: 'Здание (сооружение) объектов обувной промышленности',
  },
  {
    subCLass2: 'CBD',
    title:
      'Здание (сооружение) объектов промышленности вязанных и трикотажных изделий, верхней одежды',
  },
  {
    subCLass2: 'CBE',
    title:
      'Здание (сооружение) объектов промышленности ковров и ковровых изделий',
  },
  {
    subCLass2: 'CBF',
    title:
      'Здание (сооружение) объектов промышленности нетканых текстильных материалов и изделий из них, кроме одежды',
  },
  {
    subCLass2: 'CBG',
    title: 'Объекты текстильной промышленности',
  },
  {
    subCLass3: 'CBGA',
    title: 'Здание (сооружение) объектов предприятий хлопкоочистки',
  },
  {
    subCLass3: 'CBGB',
    title:
      'Здание (сооружение) объектов производства изделий из льна и пенько-джута',
  },
  {
    subCLass3: 'CBGC',
    title: 'Здание (сооружение) объектов производства изделий из стекловолокна',
  },
  {
    subCLass3: 'CBGD',
    title: 'Здание (сооружение) объектов производства изделий из текстиля',
  },
  {
    subCLass3: 'CBGE',
    title: 'Здание (сооружение) объектов производства льна и пенько-джута',
  },
  {
    subCLass3: 'CBGF',
    title: 'Здание (сооружение) объектов производства полуфабрикатов',
  },
  {
    subCLass3: 'CBGG',
    title: 'Здание (сооружение) объектов производства тканей с ПВХ покрытием',
  },
  {
    subCLass3: 'CBGH',
    title: 'Здание (сооружение) объектов производства хлопчатобумажных тканей',
  },
  {
    subCLass3: 'CBGJ',
    title: 'Здание (сооружение) объектов производства шелковых нитей, тканей',
  },
  {
    subCLass3: 'CBGK',
    title: 'Здание (сооружение) объектов производства шерстяных тканей',
  },
  {
    subCLass: 'CC',
    title: 'Объекты металлургии',
  },
  {
    subCLass2: 'CCA',
    title: 'Здание (сооружение) объектов сталеплавильного производства',
  },
  {
    subCLass2: 'CCB',
    title: 'Здание (сооружение) объектов прокатного производства металлургии',
  },
  {
    subCLass2: 'CCC',
    title: 'Объекты промышленности драгоценных, цветных металлов',
  },
  {
    subCLass3: 'CCCA',
    title: 'Здание (сооружение) объектов вторичной цветной металлургии',
  },
  {
    subCLass3: 'CCCB',
    title:
      'Здание (сооружение) объектов медеплавильного и медеэлектролитного производства',
  },
  {
    subCLass3: 'CCCC',
    title: 'Здание (сооружение) объектов никель-кобальтового производства',
  },
  {
    subCLass3: 'CCCD',
    title: 'Здание (сооружение) объектов обработки цветных металлов',
  },
  {
    subCLass3: 'CCCE',
    title:
      'Здание (сооружение) объектов производства алюминиевой и электродной промышленности',
  },
  {
    subCLass3: 'CCCF',
    title: 'Здание (сооружение) объектов производства драгоценных металлов',
  },
  {
    subCLass3: 'CCCG',
    title:
      'Здание (сооружение) объектов производства редких и полупроводниковых материалов',
  },
  {
    subCLass3: 'CCCH',
    title:
      'Здание (сооружение) объектов производства тугоплавких металлов и твердых сплавов',
  },
  {
    subCLass3: 'CCCJ',
    title: 'Здание (сооружение) объектов ртутного и сурьмяного производства',
  },
  {
    subCLass3: 'CCCK',
    title: 'Здание (сооружение) объектов свинцового производства',
  },
  {
    subCLass3: 'CCCL',
    title: 'Здание (сооружение) объектов титано-магниевого производства',
  },
  {
    subCLass3: 'CCCM',
    title: 'Здание (сооружение) печей промышленности алюминия',
  },
  {
    subCLass3: 'CCCN',
    title: 'Здание (сооружение) объектов цинковых заводов',
  },
  {
    subCLass2: 'CCD',
    title:
      'Здание (сооружение) объектов промышленности стальных труб, металлоконструкций, полых профилей и фитингов',
  },
  {
    subCLass2: 'CCE',
    title: 'Здание (сооружение) объектов промышленности ядерного топлива',
  },
  {
    subCLass2: 'CCF',
    title: 'Вспомогательные объекты металлургической промышленности',
  },
  {
    subCLass3: 'CCFA',
    title:
      'Здание (сооружение) вспомогательных объектов металлургических производств',
  },
  {
    subCLass3: 'CCFB',
    title:
      'Здание (сооружение) объектов газового хозяйства металлургических производств',
  },
  {
    subCLass3: 'CCFC',
    title: 'Здание (сооружение) объектов агломерационного производства',
  },
  {
    subCLass3: 'CCFD',
    title:
      'Сооружение сетей водоснабжения, канализации и очистки стоков предприятий металлургии',
  },
  {
    subCLass3: 'CCFF',
    title: 'Здание (сооружение) объектов теплосиловых хозяйств',
  },
  {
    subCLass3: 'CCFJ',
    title:
      'Надшахтные комплексы и другие сооружения рудников при подземном способе разработки',
  },
  {
    subCLass2: 'CCH',
    title:
      'Здание (сооружение) объектов предприятий по производству стальных   конструкций    для    мостов',
  },
  {
    subCLass2: 'CCJ',
    title:
      'Здание (сооружение) объектов предприятий по производству алюминиевых строительных конструкций',
  },
  {
    subCLass: 'CD',
    title: 'Объекты пищевой промышленности',
  },
  {
    subCLass2: 'CDA',
    title: 'Объекты мясной, молочной, масложировой промышленности',
  },
  {
    subCLass3: 'CDAA',
    title: 'Здание (сооружение) объектов масложирового производства',
  },
  {
    subCLass3: 'CDAB',
    title: 'Здание (сооружение) объектов молочной промышленности',
  },
  {
    subCLass3: 'CDAC',
    title: 'Здание (сооружение) объектов мясной промышленности',
  },
  {
    subCLass2: 'CDB',
    title: 'Объекты плодоовощной промышленности',
  },
  {
    subCLass3: 'CDBA',
    title:
      'Здание (сооружение) объектов переработки плодов и овощей в виде консервов',
  },
  {
    subCLass3: 'CDBB',
    title:
      'Здание (сооружение) объектов переработки плодов и овощей в виде неконсервированных (нефасованных) продуктов',
  },
  {
    subCLass2: 'CDC',
    title: 'Объекты промышленности безалкогольных напитков',
  },
  {
    subCLass3: 'CDCA',
    title:
      'Здание (сооружение) объектов безалкогольного производства и розлива воды, минеральной воды',
  },
  {
    subCLass3: 'CDCB',
    title:
      'Здание (сооружение) объектов производства и розлива безалкогольных соков',
  },
  {
    subCLass2: 'CDD',
    title: 'Объекты промышленности алкогольных напитков',
  },
  {
    subCLass3: 'CDDA',
    title: 'Здание (сооружение) объектов винодельческого производства',
  },
  {
    subCLass3: 'CDDB',
    title: 'Здание (сооружение) объектов пивобезалкогольного производства',
  },
  {
    subCLass3: 'CDDC',
    title: 'Здание (сооружение) объектов спиртового производства',
  },
  {
    subCLass2: 'CDE',
    title: 'Объекты промышленности бакалейных продуктов',
  },
  {
    subCLass3: 'CDEA',
    title: 'Здание (сооружение) объектов переработки чая',
  },
  {
    subCLass3: 'CDEB',
    title:
      'Здание (сооружение) объектов предприятий пищепроизводства, пищевых концентратов и макаронных изделий',
  },
  {
    subCLass3: 'CDEC',
    title: 'Здание (сооружение) объектов производства кофе',
  },
  {
    subCLass3: 'CDED',
    title:
      'Здание (сооружение) объектов производства мукомольно- крупяной промышленности',
  },
  {
    subCLass3: 'CDEE',
    title: 'Здание (сооружение) объектов производства сахара и патоки',
  },
  {
    subCLass3: 'CDEF',
    title:
      'Здание (сооружение) объектов производства соли, приправ и пряностей',
  },
  {
    subCLass3: 'CDEG',
    title: 'Здание (сооружение) объектов производства солода и крахмала',
  },
  {
    subCLass2: 'CDF',
    title: 'Объекты промышленности пищевых продуктов и блюд',
  },
  {
    subCLass3: 'CDFA',
    title:
      'Здание (сооружение) объектов производства готовых блюд и продуктов питания',
  },
  {
    subCLass3: 'CDFB',
    title:
      'Здание (сооружение) объектов производства полуфабрикатов и кулинарных изделий с первичной и без первичной обработки',
  },
  {
    subCLass2: 'CDG',
    title: 'Объекты промышленности прочих пищевых продуктов',
  },
  {
    subCLass3: 'CDGA',
    title: 'Здание (сооружение) объектов производства детского питания',
  },
  {
    subCLass3: 'CDGB',
    title: 'Здание (сооружение) объектов производства дрожжей',
  },
  {
    subCLass3: 'CDGC',
    title: 'Здание (сооружение) объектов производства комбикормов',
  },
  {
    subCLass2: 'CDH',
    title: 'Объекты промышленности хлебобулочных и кондитерских изделий',
  },
  {
    subCLass3: 'CDHA',
    title: 'Здание (сооружение) объектов производства кондитерских изделий',
  },
  {
    subCLass3: 'CDHB',
    title: 'Здание (сооружение) объектов производства хлебопекарных изделий',
  },
  {
    subCLass2: 'CDJ',
    title: 'Объекты рыбной промышленности',
  },
  {
    subCLass3: 'CDJA',
    title: 'Здание (сооружение) объектов предприятий консервирования рыбы',
  },
  {
    subCLass3: 'CDJB',
    title: 'Здание (сооружение) объектов предприятий рыбной промышленности',
  },
  {
    subCLass2: 'CDK',
    title: 'Объекты табачной промышленности',
  },
  {
    subCLass3: 'CDKA',
    title: 'Здание (сооружение) объектов переработки табака',
  },
  {
    subCLass2: 'CDL',
    title:
      'Здание (сооружение) вспомогательных объектов пищевой промышленности',
  },
  {
    subCLass2: 'CDM',
    title:
      'Здание (сооружение) объектов предприятий по производству  технологического оборудования для торговли и общественного питания',
  },
  {
    subCLass: 'CE',
    title: 'Объекты производств общего машиностроения',
  },
  {
    subCLass2: 'CEA',
    title: 'Объекты машиностроения для различных производств',
  },
  {
    subCLass3: 'CEAA',
    title:
      'Здание (сооружение) объектов машиностроения для литейного производства',
  },
  {
    subCLass3: 'CEAB',
    title:
      'Здание (сооружение) объектов металлургического, тяжелого и транспортного машиностроения',
  },
  {
    subCLass3: 'CEAC',
    title:
      'Здание (сооружение) объектов производства бумагоделательного и целлюлозного оборудования',
  },
  {
    subCLass3: 'CEAD',
    title:
      'Здание (сооружение) объектов производства машин для переработки и производства изделий из пластмасс и резины',
  },
  {
    subCLass3: 'CEAE',
    title:
      'Здание (сооружение) объектов производства машин текстильной промышленности',
  },
  {
    subCLass3: 'CEAF',
    title:
      'Здание (сооружение) объектов производства оборудования для пищевой промышленности, промышленности напитков и табачных изделий',
  },
  {
    subCLass3: 'CEAG',
    title:
      'Здание (сооружение) объектов производства офисной техники и оборудования, кроме компьютеров и периферийного оборудования',
  },
  {
    subCLass3: 'CEAH',
    title:
      'Здание (сооружение) объектов предприятий по производству сварных конструкций для машиностроения',
  },
  {
    subCLass3: 'CEAJ',
    title:
      'Здание (сооружение) объектов предприятий по производству изделий общемашиностроительного применения (редукторов, гидрооборудования, фильтрующих устройств, строительных деталей)',
  },
  {
    subCLass2: 'CEB',
    title: 'Объекты машиностроения добычи полезных ископаемых',
  },
  {
    subCLass3: 'CEBA',
    title:
      'Здание (сооружение) объектов нефтегазодобывающего и нефтегазоперерабатывающего машиностроения',
  },
  {
    subCLass3: 'CEBB',
    title:
      'Здание (сооружение) объектов производства горношахтного оборудования',
  },
  {
    subCLass2: 'CEC',
    title:
      'Здание (сооружение) объектов прокатного производства общего машиностроения',
  },
  {
    subCLass2: 'CED',
    title:
      'Объекты промышленности гидравлического и пневматического силового оборудования',
  },
  {
    subCLass3: 'CEDA',
    title:
      'Здание (сооружение) объектов предприятий компрессорного машиностроения',
  },
  {
    subCLass3: 'CEDB',
    title:
      'Здание (сооружение) объектов производства гидравлического и пневматического силового оборудования',
  },
  {
    subCLass3: 'CEDC',
    title:
      'Здание (сооружение) объектов производства противопожарного гидравлического оборудования',
  },
  {
    subCLass2: 'CEE',
    title:
      'Объекты промышленности двигателей, турбин и комплектующих, кроме авиационных, автомобильных и мотоциклетных',
  },
  {
    subCLass3: 'CEEA',
    title:
      'Здание (сооружение) объектов производства комплектующих изделий, узлов и деталей двигателей',
  },
  {
    subCLass3: 'CEEB',
    title: 'Здание (сооружение) объектов производства специальных двигателей',
  },
  {
    subCLass3: 'CEEC',
    title: 'Здание (сооружение) объектов производства турбин',
  },
  {
    subCLass2: 'CEF',
    title:
      'Объекты промышленности машин и оборудования для сельского и лесного хозяйства',
  },
  {
    subCLass3: 'CEFA',
    title:
      'Здание (сооружение) объектов лесохозяйственного машиностроения и автотрактороремонтного производства',
  },
  {
    subCLass3: 'CEFB',
    title:
      'Здание (сооружение) объектов машиностроения для сельского хозяйства',
  },
  {
    subCLass2: 'CEG',
    title:
      'Здание (сооружение) объектов промышленности машин и оборудования коммунального назначения',
  },
  {
    subCLass2: 'CEH',
    title: 'Объекты промышленности печей, термокамер и печных горелок',
  },
  {
    subCLass3: 'CEHA',
    title: 'Здание (сооружение) объектов производства печей и горелок',
  },
  {
    subCLass3: 'CEHB',
    title:
      'Здание (сооружение) объектов производства электротермического оборудования',
  },
  {
    subCLass3: 'CEHC',
    title: 'Сооружение сушила',
  },
  {
    subCLass3: 'CEHD',
    title: 'Сооружение теплообменного устройства',
  },
  {
    subCLass3: 'CEHF',
    title: 'Сооружение термокамеры',
  },
  {
    subCLass2: 'CEJ',
    title:
      'Здание (сооружение) объектов промышленности подшипников, зубчатых передач, элементов механических передач и приводов',
  },
  {
    subCLass2: 'CEK',
    title:
      'Здание (сооружение) объектов промышленности подъемно-транспортного оборудования, станков, машин, средств непрерывного транспорта',
  },
  {
    subCLass2: 'CEL',
    title:
      'Здание (сооружение) объектов промышленности промышленного холодильного и вентиляционного оборудования, фильтров',
  },
  {
    subCLass2: 'CEM',
    title:
      'Здание (сооружение) объектов промышленности станков, машин, оборудования и инструмента',
  },
  {
    subCLass2: 'CEN',
    title: 'Вспомогательные объекты общего машиностроения',
  },
  {
    subCLass3: 'CENA',
    title: 'Здание (сооружение) объектов машиностроительных заводов',
  },
  {
    subCLass3: 'CENB',
    title:
      'Здание (сооружение) объектов по ремонту и сборке оборудования машиностроения',
  },
  {
    subCLass: 'CF',
    title: 'Объекты производств транспортного машиностроения',
  },
  {
    subCLass2: 'CFA',
    title: 'Объекты кораблестроения и судостроения',
  },
  {
    subCLass3: 'CFAA',
    title:
      'Здание (сооружение) объектов производства и ремонта кораблей, судов и плавучих конструкций',
  },
  {
    subCLass3: 'CFAB',
    title:
      'Здание (сооружение) объектов производства прогулочных и спортивных судов',
  },
  {
    subCLass3: 'CFAC',
    title:
      'Специальное здание (сооружение) для производства и ремонта кораблей, судов и плавучих конструкций',
  },
  {
    subCLass2: 'CFB',
    title:
      'Здание (сооружение) объектов промышленности двигателей внутреннего сгорания',
  },
  {
    subCLass2: 'CFC',
    title:
      'Объекты промышленности железнодорожных локомотивов и подвижного состава',
  },
  {
    subCLass3: 'CFCA',
    title:
      'Здание (сооружение) объектов предприятий по ремонту подвижного состава',
  },
  {
    subCLass3: 'CFCB',
    title:
      'Здание (сооружение) объектов производства железнодорожных локомотивов и подвижного состава',
  },
  {
    subCLass3: 'CFCC',
    title:
      'Здание (сооружение) объектов производства оборудования для управления движением',
  },
  {
    subCLass3: 'CFCD',
    title: 'Здание (сооружение) объектов тепловозостроения',
  },
  {
    subCLass3: 'CFCE',
    title:
      'Здание (сооружение) объектов предприятий по производству тормозного оборудования для железнодорожного подвижного состава',
  },
  {
    subCLass2: 'CFD',
    title: 'Объекты промышленности колесных и гусенечных транспортных средств',
  },
  {
    subCLass3: 'CFDA',
    title: 'Здание (сооружение) объектов производства автобусов и троллейбусов',
  },
  {
    subCLass3: 'CFDB',
    title: 'Здание (сооружение) объектов производства автомобилей',
  },
  {
    subCLass3: 'CFDC',
    title: 'Здание (сооружение) объектов производства военных боевых машин',
  },
  {
    subCLass3: 'CFDD',
    title:
      'Здание (сооружение) объектов производства и обслуживания автомобильных прицепов, агрегатов',
  },
  {
    subCLass3: 'CFDE',
    title:
      'Здание (сооружение) объектов производства мотоциклов, велосипедов и инвалидных колясок',
  },
  {
    subCLass3: 'CFDF',
    title:
      'Здание (сооружение) объектов производства тракторов и специальной техники',
  },
  {
    subCLass3: 'CFDG',
    title: 'Здание (сооружение) объектов предприятий автосборочных',
  },
  {
    subCLass3: 'CFDH',
    title:
      'Здание (сооружение) объектов предприятий по сельскохозяйственному машиностроению',
  },
  {
    subCLass3: 'CFDJ',
    title:
      'Здание (сооружение) объектов предприятий по строительно-дорожному машиностроению',
  },
  {
    subCLass3: 'CFDK',
    title:
      'Здание (сооружение) объектов предприятий по производству бульдозеров, скреперов, экскаваторов и узлов для экскаваторов',
  },
  {
    subCLass2: 'CFE',
    title:
      'Объекты промышленности летательных аппаратов, включая космические и соответствующего оборудования',
  },
  {
    subCLass3: 'CFEA',
    title:
      'Здание (сооружение) объектов кузнечно-прессовых и прокатный операций при производстве летательных аппаратов',
  },
  {
    subCLass3: 'CFEB',
    title:
      'Здание (сооружение) объектов литейных операций при производстве летательных аппаратов',
  },
  {
    subCLass3: 'CFEC',
    title:
      'Здание (сооружение) объектов операций по окраске и нанесению покрытий при производстве летательных аппаратов',
  },
  {
    subCLass3: 'CFED',
    title:
      'Здание (сооружение) объектов опытно­конструкторских бюро при производстве летательных аппаратов',
  },
  {
    subCLass3: 'CFEE',
    title:
      'Здание (сооружение) объектов производства двигателей для летательных аппаратов',
  },
  {
    subCLass3: 'CFEF',
    title:
      'Здание (сооружение) объектов производства узлов и агрегатов для летательных аппаратов',
  },
  {
    subCLass3: 'CFEG',
    title:
      'Здание (сооружение) объектов сборочных операций при производстве летательных аппаратов',
  },
  {
    subCLass3: 'CFEH',
    title:
      'Здание (сооружение) вспомогательных объектов при производстве летательных аппаратов',
  },
  {
    subCLass2: 'CFF',
    title: 'Объекты автомобильного мотостроения и машиностроения ',
  },
  {
    subCLass3: 'CFFA',
    title:
      'Здание (сооружение) объектов предприятий по автомобильному моторостроению',
  },
  {
    subCLass3: 'CFFB',
    title:
      'Здание (сооружение) объектов предприятий по производству подшипников',
  },
  {
    subCLass: 'CG',
    title: 'Объекты промышленности готовых изделий (неметаллических)',
  },
  {
    subCLass2: 'CGA',
    title: 'Здание (сооружение) объектов инновационной инфраструктуры',
  },
  {
    subCLass2: 'CGB',
    title:
      'Здание (сооружение) объектов производства бытовых неэлектрических приборов',
  },
  {
    subCLass2: 'CGC',
    title:
      'Здание (сооружение) объектов производства готовых бытовых неэлектрических изделий',
  },
  {
    subCLass2: 'CGD',
    title:
      'Здание (сооружение) объектов производства готовых бытовых электрических изделий',
  },
  {
    subCLass2: 'CGE',
    title: 'Здание (сооружение) объектов производства игрушек',
  },
  {
    subCLass2: 'CGF',
    title: 'Здание (сооружение) объектов производства лыж из древесины',
  },
  {
    subCLass2: 'CGG',
    title:
      'Здание (сооружение) объектов производства медицинских инструментов, оборудования и материалов',
  },
  {
    subCLass2: 'CGH',
    title: 'Здание (сооружение) объектов производства музыкальных инструментов',
  },
  {
    subCLass2: 'CGJ',
    title:
      'Здание (сооружение) объектов производства оборудования для обеспечения безопасности',
  },
  {
    subCLass2: 'CGK',
    title: 'Здание (сооружение) объектов производства ювелирных изделий',
  },
  {
    subCLass2: 'CGL',
    title:
      'Здание (сооружение) вспомогательных объектов промышленности готовых изделий (неметаллических)',
  },
  {
    subCLass: 'CH',
    title:
      'Объекты промышленности готовых металлических изделий, кроме машин и оборудования',
  },
  {
    subCLass2: 'CHA',
    title:
      'Объекты промышленности метизов и прочих готовых металлических изделий',
  },
  {
    subCLass3: 'CHAA',
    title: 'Здание (сооружение) объектов метизного производства',
  },
  {
    subCLass3: 'CHAB',
    title: 'Здание (сооружение) объектов производства тары для лаков и красок',
  },
  {
    subCLass3: 'CHAC',
    title: 'Здание (сооружение) объектов производства фурнитуры',
  },
  {
    subCLass2: 'CHB',
    title:
      'Объекты промышленности обработки металлов, нанесения покрытий на металлы',
  },
  {
    subCLass3: 'CHBA',
    title: 'Здание (сооружение) объектов кузнечно-прессового производства',
  },
  {
    subCLass3: 'CHBB',
    title: 'Здание (сооружение) объектов литейного производства',
  },
  {
    subCLass3: 'CHBC',
    title: 'Здание (сооружение) объектов нанесения защитных покрытий',
  },
  {
    subCLass3: 'CHBD',
    title: 'Здание (сооружение) объектов порошковой металлургии',
  },
  {
    subCLass2: 'CHC',
    title: 'Объекты промышленности обычных вооружений',
  },
  {
    subCLass3: 'CHCA',
    title: 'Здание (сооружение) объектов производства военных боеприпасов',
  },
  {
    subCLass3: 'CHCB',
    title:
      'Здание (сооружение) объектов производства оружия, создаваемого на основе ядерных оружейных технологий, и его составных частей',
  },
  {
    subCLass3: 'CHCC',
    title:
      'Здание (сооружение) объектов производства пневматических или газовых ружей и пистолетов',
  },
  {
    subCLass3: 'CHCD',
    title: 'Здание (сооружение) объектов производства стрелкового оружия',
  },
  {
    subCLass3: 'CHCE',
    title: 'Здание (сооружение) объектов производства тяжелого оружия',
  },
  {
    subCLass2: 'CHD',
    title:
      'Объекты промышленности паровых котлов, металлических цистерн, резервуаров и прочих емкостей',
  },
  {
    subCLass3: 'CHDA',
    title:
      'Здание (сооружение) объектов производства ёмкостей, баков, резервуаров',
  },
  {
    subCLass3: 'CHDB',
    title: 'Здание (сооружение) объектов производства котлов',
  },
  {
    subCLass3: 'CHDC',
    title: 'Здание (сооружение) объектов производства реакторов',
  },
  {
    subCLass2: 'CHE',
    title:
      'Здание (сооружение) объектов промышленности режущих изделий, инструментов и универсальных скобяных изделий',
  },
  {
    subCLass2: 'CHF',
    title: 'Объекты производства строительных конструкций',
  },
  {
    subCLass: 'CJ',
    title:
      'Объекты промышленности лекарственных средств, материалов и оборудования, применяемых в медицинских целях',
  },
  {
    subCLass2: 'CJA',
    title: 'Объекты медицинской промышленности',
  },
  {
    subCLass3: 'CJAA',
    title:
      'Здание (сооружение) объектов производства белково-витаминных концентратов из Н-парафинов - паприн и газа - гаприн',
  },
  {
    subCLass3: 'CJAB',
    title:
      'Здание (сооружение) объектов производства материалов и оборудования, применяемых в медицинских целях',
  },
  {
    subCLass3: 'CJAC',
    title:
      'Здание (сооружение) объектов производства медицинских изделий из стекла',
  },
  {
    subCLass3: 'CJAD',
    title:
      'Здание (сооружение) объектов производства медицинских инструментов, приборов',
  },
  {
    subCLass2: 'CJB',
    title: 'Объекты промышленности фармацевтических субстанций',
  },
  {
    subCLass3: 'CJBA',
    title:
      'Здание (сооружение) объектов предприятий гидролизной промышленности',
  },
  {
    subCLass3: 'CJBB',
    title: 'Здание (сооружение) объектов производства антибиотиков',
  },
  {
    subCLass3: 'CJBC',
    title:
      'Здание (сооружение) объектов производства антибиотических средств защиты растений',
  },
  {
    subCLass3: 'CJBD',
    title:
      'Здание (сооружение) объектов производства бактериальных средств защиты растений',
  },
  {
    subCLass3: 'CJBE',
    title: 'Здание (сооружение) объектов производства витаминов и коферментов',
  },
  {
    subCLass3: 'CJBF',
    title:
      'Здание (сооружение) объектов производства кормового концентрата лизина, кристаллического кормового лизина',
  },
  {
    subCLass3: 'CJBG',
    title:
      'Здание (сооружение) объектов производства ферментных препаратов и высокоочищенных аминокислот',
  },
  {
    subCLass2: 'CJC',
    title: 'Объекты фармацевтической промышленности',
  },
  {
    subCLass3: 'CJCA',
    title:
      'Здание (сооружение) объектов производства антибиотиков - тилозина, биоветина и др',
  },
  {
    subCLass3: 'CJCB',
    title:
      'Здание (сооружение) объектов производства готовых лекарственных средств',
  },
  {
    subCLass3: 'CJCC',
    title: 'Здание (сооружение) объектов производства премиксов',
  },
  {
    subCLass2: 'CJE',
    title:
      'Здание (сооружение) объектов подсобного и обслуживающего назначения медицинской, фармацевтической промышленности',
  },
  {
    subCLass: 'CK',
    title:
      'Объекты промышленности продуктов древесины, деревообрабатывающей и мебельной промышленности, целлюлозо-бумажной промышленности, полиграфической промышленности',
  },
  {
    subCLass2: 'CKA',
    title:
      'Объекты полиграфической промышленности и промышленности копированных носителей информации',
  },
  {
    subCLass3: 'CKAA',
    title:
      'Здание (сооружение) объектов предприятий по тиражированию CD/DVD- дисков',
  },
  {
    subCLass3: 'CKAB',
    title:
      'Здание (сооружение) объектов предприятий полиграфической промышленности',
  },
  {
    subCLass2: 'CKB',
    title: 'Объекты промышленности изделий из бумаги и картона',
  },
  {
    subCLass3: 'CKBA',
    title: 'Здание (сооружение) объектов производства изделий из бумаги',
  },
  {
    subCLass3: 'CKBB',
    title: 'Здание (сооружение) объектов производства изделий из картона',
  },
  {
    subCLass2: 'CKC',
    title:
      'Объекты промышленности изделий из дерева, пробки, соломки и материалов для плетения',
  },
  {
    subCLass3: 'CKCA',
    title:
      'Здание (сооружение) объектов предприятий по выпуску древесно-стружечных и других видов стружечных плит',
  },
  {
    subCLass3: 'CKCB',
    title: 'Здание (сооружение) объектов производства изделий из дерева',
  },
  {
    subCLass2: 'CKD',
    title:
      'Объекты промышленности по распиловке, строганию, предварительной обработке древесины',
  },
  {
    subCLass3: 'CKDA',
    title: 'Здание (сооружение) объектов лесопильного предприятия',
  },
  {
    subCLass3: 'CKDB',
    title:
      'Здание (сооружение) объектов предприятий деревообрабатывающей промышленности',
  },
  {
    subCLass2: 'CKE',
    title:
      'Объекты промышленности целлюлозы, древесной массы, бумаги и картона',
  },
  {
    subCLass3: 'CKEA',
    title:
      'Здание (сооружение) объектов заводов макулатурной массы, хлопковой и тряпичной полумассы',
  },
  {
    subCLass3: 'CKEB',
    title:
      'Здание (сооружение) объектов заводов побочных продуктов промышленности целлюлозы, древесной массы, бумаги и картона',
  },
  {
    subCLass3: 'CKEC',
    title:
      'Здание (сооружение) объектов подготовки древесного сырья целлюлозных, древесно­массных заводов и заводов термомеханической и химикотермомеханической массы из щепы',
  },
  {
    subCLass3: 'CKED',
    title:
      'Здание (сооружение) объектов предприятий по производству бумаги и картона',
  },
  {
    subCLass3: 'CKEE',
    title:
      'Здание (сооружение) объектов производства полуфабрикатов целлюлозо­бумажной промышленности',
  },
  {
    subCLass3: 'CKEF',
    title:
      'Здание (сооружение) объектов производства целлюлозы и полуцеллюлозы',
  },
  {
    subCLass2: 'CKF',
    title: 'Здание (сооружение) объектов мебельной промышленности',
  },
  {
    subCLass2: 'CKG',
    title:
      'Объекты вспомогательной инфраструктуры промышленности продуктов древесины',
  },
  {
    subCLass3: 'CKGA',
    title:
      'Здание (сооружение) объектов вспомогательного и обслуживающего назначения целлюлозных заводов, бумажных и картонных фабрик',
  },
  {
    subCLass3: 'CKGB',
    title:
      'Здание (сооружение) объектов вспомогательного назначения предприятий по производству изделий из дерева',
  },
  {
    subCLass: 'CL',
    title:
      'Объекты промышленности резиновых, пластмассовых изделий, неметаллической минеральной продукции',
  },
  {
    subCLass2: 'CLA',
    title: 'Объекты производства пластмасс',
  },
  {
    subCLass3: 'CLAA',
    title:
      'Здание (сооружение) объектов производства изделий из стеклопластика',
  },
  {
    subCLass3: 'CLAB',
    title:
      'Здание (сооружение) объектов производства строительных изделий и материалов из пластмасс',
  },
  {
    subCLass2: 'CLB',
    title: 'Объекты производства сортового цемента, извести и гипса',
  },
  {
    subCLass3: 'CLBA',
    title:
      'Здание (сооружение) объектов подготовки компонентов для производств цемента, извести и гипса',
  },
  {
    subCLass3: 'CLBB',
    title: 'Здание (сооружение) объектов производства цемента, извести и гипса',
  },
  {
    subCLass3: 'CLBC',
    title:
      'Здание (сооружение) объектов предприятий по производству асбестоцементных изделий',
  },
  {
    subCLass2: 'CLC',
    title:
      'Объекты промышленности другой неметаллической минеральной продукции',
  },
  {
    subCLass3: 'CLCA',
    title:
      'Здание (сооружение) объектов производства изделий из абразивных материалов',
  },
  {
    subCLass3: 'CLCB',
    title: 'Здание (сооружение) объектов производства легких заполнителей',
  },
  {
    subCLass3: 'CLCC',
    title: 'Здание (сооружение) объектов производства слюдопласта',
  },
  {
    subCLass3: 'CLCD',
    title:
      'Здание (сооружение) объектов производства теплоизоляционных материалов',
  },
  {
    subCLass3: 'CLCE',
    title:
      'Здание (сооружение) объектов производства электроизоляционных материалов',
  },
  {
    subCLass2: 'CLD',
    title: 'Объекты промышленности изделий из бетона, цемента и гипса',
  },
  {
    subCLass3: 'CLDA',
    title: 'Здание (сооружение) объектов производства асбоцементных изделий',
  },
  {
    subCLass3: 'CLDB',
    title:
      'Здание (сооружение) объектов производства известковых и вяжущих материалов и изделий из них, известняковой муки',
  },
  {
    subCLass3: 'CLDC',
    title: 'Здание (сооружение) объектов производства изделий из гипса',
  },
  {
    subCLass3: 'CLDD',
    title:
      'Здание (сооружение) объектов производства мягких кровельных материалов',
  },
  {
    subCLass3: 'CLDE',
    title:
      'Здание (сооружение) объектов производства сборных железобетонных и бетонных конструкций и изделий',
  },
  {
    subCLass3: 'CLDF',
    title:
      'Здание (сооружение) объектов предприятий по производству крупных блоков, панелей и других конструкций из ячеистого и плотного силикатобетона ',
  },
  {
    subCLass2: 'CLE',
    title:
      'Объекты промышленности керамических и фарфорофаянсовых материалов и изделий',
  },
  {
    subCLass3: 'CLEA',
    title:
      'Здание (сооружение) объектов производства изделий из фарфора, фарфоро- фаянса',
  },
  {
    subCLass3: 'CLEB',
    title: 'Здание (сооружение) объектов производства стеновых материалов',
  },
  {
    subCLass3: 'CLEC',
    title: 'Здание (сооружение) объектов производства строительной керамики',
  },
  {
    subCLass3: 'CLED',
    title:
      'Здание (сооружение) объектов предприятий по производству художественной керамики',
  },
  {
    subCLass2: 'CLF',
    title: 'Объекты промышленности резиновых изделий, шинная промышленность',
  },
  {
    subCLass3: 'CLFA',
    title:
      'Здание (сооружение) объектов производства изделий из резины для обувной промышленности',
  },
  {
    subCLass3: 'CLFB',
    title: 'Здание (сооружение) объектов производства шин',
  },
  {
    subCLass2: 'CLG',
    title: 'Объекты промышленности силикатных и огнеупорных изделий',
  },
  {
    subCLass3: 'CLGA',
    title: 'Здание (сооружение) объектов производства огнеупорных изделий',
  },
  {
    subCLass3: 'CLGB',
    title:
      'Здание (сооружение) объектов производства силикатных стеновых материалов',
  },
  {
    subCLass3: 'CLGC',
    title:
      'Здание (сооружение) объектов предприятий по обжигу огнеупорного сырья и производству порошков и мертелей',
  },
  {
    subCLass2: 'CLH',
    title: 'Объекты промышленности стекла и изделий из стекла',
  },
  {
    subCLass3: 'CLHA',
    title: 'Здание (сооружение) объектов предприятия стекольного производства',
  },
  {
    subCLass3: 'CLHB',
    title: 'Здание (сооружение) объектов производства стекловолокна',
  },
  {
    subCLass3: 'CLHC',
    title: 'Здание (сооружение) объектов производства стеклянной тары',
  },
  {
    subCLass3: 'CLHD',
    title:
      'Здание (сооружение) объектов предприятий по производству технологического оборудования для стекольной промышленности',
  },
  {
    subCLass2: 'CLJ',
    title: 'Объекты промышленности резки, обработки и отделки камня',
  },
  {
    subCLass3: 'CLJA',
    title: 'Здание (сооружение) объектов гравийно-песчаного производства',
  },
  {
    subCLass3: 'CLJB',
    title: 'Здание (сооружение) объектов камнеобрабатывающего производства',
  },
  {
    subCLass2: 'CLK',
    title:
      'Объекты вспомогательной инфраструктуры промышленности резиновых, пластмассовых изделий, неметаллической минеральной продукции',
  },
  {
    subCLass3: 'CLKA',
    title:
      'Здание (сооружение) вспомогательных объектов инфраструктуры производства керамических и фарфорофаянсовых изделий',
  },
  {
    subCLass3: 'CLKB',
    title:
      'Здание (сооружение) вспомогательных объектов производства изделий из бетона, цемента и гипса',
  },
  {
    subCLass: 'CM',
    title: 'Объекты радиоэлектронной и электротехнической промышленности',
  },
  {
    subCLass2: 'CMA',
    title:
      'Здание (сооружение) объектов аккумуляторной промышленности и производства элементов электропитания',
  },
  {
    subCLass2: 'CMB',
    title:
      'Здание (сооружение) объектов промышленности компьютеров, периферийного оборудования и коммуникационного оборудования',
  },
  {
    subCLass2: 'CMC',
    title:
      'Здание (сооружение) объектов промышленности облучающего и электротерапевтического оборудования, применяемого в медицинских целях',
  },
  {
    subCLass2: 'CMD',
    title:
      'Здание (сооружение) объектов промышленности оптических приборов, фото- и кинооборудования',
  },
  {
    subCLass2: 'CME',
    title:
      'Здание (сооружение) объектов промышленности электрических ламп и осветительного оборудования',
  },
  {
    subCLass2: 'CMF',
    title:
      'Здание (сооружение) объектов промышленности электрической распределительной и регулирующей аппаратуры, кабелей и кабельной арматуры',
  },
  {
    subCLass2: 'CMG',
    title:
      'Здание (сооружение) объектов промышленности электродвигателей, электрогенераторов, трансформаторов и, подъемно -транспортного оборудования',
  },
  {
    subCLass2: 'CMH',
    title:
      'Здание (сооружение) объектов промышленности электроники и электрооборудования',
  },
  {
    subCLass2: 'CMJ',
    title:
      'Здание (сооружение) объектов промышленности электронных компонентов и печатных плат',
  },
  {
    subCLass: 'CN',
    title: 'Объекты химической промышленности',
  },
  {
    subCLass2: 'CNA',
    title: 'Объекты газовой промышленности',
  },
  {
    subCLass3: 'CNAA',
    title:
      'Здание (сооружение) объектов переработки газа по схеме низкотемпературной конденсации',
  },
  {
    subCLass3: 'CNAB',
    title: 'Здание (сооружение) объектов получения прочих газов',
  },
  {
    subCLass3: 'CNAC',
    title:
      'Здание (сооружение) объектов предприятий кислородной промышленности',
  },
  {
    subCLass3: 'CNAD',
    title: 'Здание (сооружение) объектов предприятий получения инертного газа',
  },
  {
    subCLass2: 'CNB',
    title: 'Объекты промышленности боеприпасов и спецхимии',
  },
  {
    subCLass3: 'CNBA',
    title: 'Здание (сооружение) объектов выпуска спичек',
  },
  {
    subCLass3: 'CNBB',
    title:
      'Здание (сооружение) объектов производства взрывчатых веществ и изделий на их основе',
  },
  {
    subCLass3: 'CNBC',
    title:
      'Здание (сооружение) объектов производства пороха и твердого ракетного топлива',
  },
  {
    subCLass2: 'CNC',
    title: 'Объекты промышленности красок, лаков, клеев, эфирных масел',
  },
  {
    subCLass3: 'CNCA',
    title: 'Здание (сооружение) объектов производства клея',
  },
  {
    subCLass3: 'CNCB',
    title: 'Здание (сооружение) объектов производства лаков и красок',
  },
  {
    subCLass3: 'CNCC',
    title: 'Здание (сооружение) объектов производства химических красителей',
  },
  {
    subCLass3: 'CNCD',
    title: 'Здание (сооружение) объектов эфиромасличного производства',
  },
  {
    subCLass2: 'CND',
    title:
      'Объекты промышленности мыла и моющих, чистящих и полирующих средств, парфюмерных и косметических средств',
  },
  {
    subCLass2: 'CNE',
    title: 'Объекты промышленности неорганических химических веществ',
  },
  {
    subCLass3: 'CNEA',
    title: 'Здание (сооружение) объектов предприятий содовой промышленности',
  },
  {
    subCLass3: 'CNEB',
    title:
      'Здание (сооружение) объектов производства прочих неорганических веществ',
  },
  {
    subCLass3: 'CNEC',
    title: 'Здание (сооружение) объектов производства серной кислоты',
  },
  {
    subCLass3: 'CNED',
    title:
      'Здание (сооружение) объектов производства химических реактивов и особо чистых веществ',
  },
  {
    subCLass3: 'CNEE',
    title: 'Здание (сооружение) объектов химико­фотографического производства',
  },
  {
    subCLass2: 'CNF',
    title: 'Объекты промышленности органических химических веществ',
  },
  {
    subCLass3: 'CNFA',
    title:
      'Здание (сооружение) объектов предприятий лесохимической промышленности',
  },
  {
    subCLass3: 'CNFB',
    title:
      'Здание (сооружение) объектов предприятий метанольной промышленности',
  },
  {
    subCLass3: 'CNFC',
    title: 'Здание (сооружение) объектов предприятий органического синтеза',
  },
  {
    subCLass3: 'CNFD',
    title: 'Здание (сооружение) объектов производства пищевых кислот',
  },
  {
    subCLass3: 'CNFE',
    title: 'Здание (сооружение) объектов производства синтетического каучука',
  },
  {
    subCLass3: 'CNFF',
    title: 'Здание (сооружение) объектов производства фосфатных удобрений',
  },
  {
    subCLass3: 'CNFG',
    title: 'Здание (сооружение) объектов предприятий хлорной промышленности',
  },
  {
    subCLass2: 'CNG',
    title: 'Объекты промышленности пластмасс и синтетических смол',
  },
  {
    subCLass3: 'CNGA',
    title:
      'Здание (сооружение) объектов производства полуфабрикатов из синтетических смол и пластических масс',
  },
  {
    subCLass3: 'CNGB',
    title:
      'Здание (сооружение) объектов производства синтетических смол и пластических масс',
  },
  {
    subCLass2: 'CNH',
    title: 'Объекты промышленности удобрений и азотных соединений',
  },
  {
    subCLass3: 'CNHA',
    title:
      'Здание (сооружение) объектов предприятий по производству торфяных удобрений',
  },
  {
    subCLass3: 'CNHB',
    title:
      'Здание (сооружение) объектов производства пестицидов, инсектицидов, родентицидов, фунгицидов, гербицидов, акарицидов, биоцидов',
  },
  {
    subCLass3: 'CNHC',
    title: 'Здание (сооружение) объектов промышленности удобрений',
  },
  {
    subCLass3: 'CNHD',
    title:
      'Здание (сооружение) сушильных объектов при производстве удобрений и химическом производстве',
  },
  {
    subCLass3: 'CNHE',
    title:
      'Здание (сооружение) печей при производстве удобрений и химическом производстве',
  },
  {
    subCLass2: 'CNJ',
    title: 'Объекты промышленности химических волокон',
  },
  {
    subCLass3: 'CNJA',
    title:
      'Здание (сооружение) объектов производства компонентов химических волокон и нитей',
  },
  {
    subCLass3: 'CNJB',
    title:
      'Здание (сооружение) объектов производства химических волокон и нитей',
  },
  {
    type: 'CNJB010',
    title: 'Здание (сооружение) объектов производства вискозных волокон',
  },
  {
    type: 'CNJB020',
    title: 'Здание (сооружение) объектов производства синтетических волокон',
  },
  {
    subCLass2: 'CNK',
    title: 'Объекты вспомогательной инфраструктуры химической промышленности',
  },
  {
    subCLass3: 'CNKA',
    title:
      'Здание (сооружение) вспомогательных объектов производства синтетических смол и пластических масс',
  },
  {
    subCLass3: 'CNKB',
    title:
      'Здание (сооружение) вспомогательных объектов производства удобрений и иного химического производства',
  },
  {
    subCLass3: 'CNKC',
    title:
      'Здание (сооружение) объектов абсорбции и улавливания газов при производстве удобрений и ином химическом производстве',
  },
  {
    subCLass3: 'CNKD',
    title:
      'Здание (сооружение) объектов обезвреживания результатов деятельности предприятий химической промышленности',
  },
  {
    subCLass3: 'CNKE',
    title:
      'Здание (сооружение) объектов обеспечивающих производств химической промышленности',
  },
  {
    subCLass3: 'CNKF',
    title:
      'Здание (сооружение) объектов приема, отгрузки продукции при производстве удобрений и химическом производстве',
  },
  {
    subCLass3: 'CNKG',
    title:
      'Здание (сооружение) объектов транспортировки продукции при производстве удобрений и в ином химическом производстве',
  },
  {
    subCLass3: 'CNKH',
    title: 'Здание (сооружение) объектов упаковки и фасовки продукции',
  },
  {
    subCLass3: 'CNKJ',
    title: 'Здание (сооружение) объектов химводоподготовки воды',
  },
  {
    subCLass3: 'CNKK',
    title:
      'Здание (сооружение) ремонтно-механических объектов химической промышленности',
  },
  {
    subCLass2: 'CNL',
    title:
      'Здание (сооружение) объектов предприятий по производству оборудования для химического машиностроения',
  },
  {
    subCLass: 'CP',
    title: 'Объекты кожевной и меховой промышленности',
  },
  {
    subCLass2: 'CPA',
    title:
      'Здание (сооружение) объектов предприятий кожевной и первичной обработки кожсырья',
  },
  {
    subCLass2: 'CPB',
    title:
      'Здание (сооружение) объектов предприятий по производству искусственной кожи, обувных картонов и пленочных материалов',
  },
  {
    subCLass2: 'CPC',
    title: 'Здание (сооружение) объектов предприятий кожгалантерейных',
  },
  {
    subCLass2: 'CPD',
    title: 'Здание (сооружение) объектов меховых и овчинно-шубных предприятий',
  },
  {
    subCLass2: 'CPE',
    title: 'Здание (сооружение) объектов предприятий по производству обуви',
  },
  {
    class: 'D',
    title: 'Объекты энергетики и электроэнергетики',
  },
  {
    subCLass: 'DA',
    title: 'Объекты атомной энергетики',
  },
  {
    subCLass2: 'DAA',
    title:
      'Здание (сооружение) атомной электрической станции малой мощности стационарного расположения',
  },
  {
    subCLass2: 'DAB',
    title:
      'Здание (сооружение) атомной электрической станции с водо-водяным энергетическим реактором',
  },
  {
    subCLass2: 'DAC',
    title:
      'Здание (сооружение) атомной электрической станции с реактором канального типа',
  },
  {
    subCLass2: 'DAD',
    title:
      'Здание (сооружение) атомной электрической станции с реактором на быстрых нейтронах',
  },
  {
    subCLass: 'DB',
    title: 'Объекты гидроэнергетики',
  },
  {
    subCLass2: 'DBA',
    title:
      'Здание (сооружение) гидроэлектростанции (Объекты станционного узла)',
  },
  {
    subCLass2: 'DBB',
    title: 'Водоподпорное (защитное) сооружение',
  },
  {
    subCLass2: 'DBC',
    title: 'Дренажное сооружение',
  },
  {
    subCLass2: 'DBD',
    title:
      'Сооружение водосбросное (водоспускное и водовыпускное), водозаборное, водовод',
  },
  {
    subCLass2: 'DBE',
    title: 'Сооружение гидроэнергетики специальное',
  },
  {
    subCLass: 'DC',
    title: 'Объекты тепловой электроэнергетики',
  },
  {
    subCLass2: 'DCA',
    title: 'Теплоэлектростанция',
  },
  {
    subCLass3: 'DCAA',
    title: 'Электрическая станция на возобновляемых источниках энергии',
  },
  {
    subCLass3: 'DCAB',
    title: 'Электрическая станция на невозобновляемых источниках энергии',
  },
  {
    subCLass2: 'DCB',
    title:
      'Сооружение водоподготовительной установки паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCC',
    title:
      'Здание (сооружение) вспомогательных объектов паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCD',
    title:
      'Сооружение объектов гидроуборки топливоподачи паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCE',
    title:
      'Сооружение объектов золошлакоудаления паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCF',
    title:
      'Сооружение объектов технологической коммуникации паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCG',
    title:
      'Сооружение сетей обеспечения газообразным топливом паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCH',
    title:
      'Сооружение сетей обеспечения жидким топливом и маслохозяйство паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCJ',
    title:
      'Сооружение сетей обеспечения твердым топливом паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCK',
    title:
      'Сооружение сетей производства и разводки спецгазов паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DCL',
    title:
      'Сооружение сетей технического водоснабжения паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass: 'DE',
    title: 'Объекты обеспечения электроэнергией',
  },
  {
    subCLass2: 'DEA',
    title:
      'Переходы воздушных линий электропередач, коммуникационные тоннели, объекты канализации',
  },
  {
    subCLass3: 'DEAA',
    title: 'Сооружение перехода воздушных линий электропередач',
  },
  {
    subCLass3: 'DEAB',
    title: 'Сооружение коммуникационного тоннеля',
  },
  {
    subCLass3: 'DEAC',
    title: 'Воздушная линия электропередачи',
  },
  {
    type: 'DEAC010',
    title: 'Магистраль воздушной линии электропередачи',
  },
  {
    type: 'DEAC020',
    title: 'Линейное ответвление от  воздушной линии электропередачи',
  },
  {
    type: 'DEAC030',
    title: 'Ответвление от воздушной линии электропередачи к вводу',
  },
  {
    type: 'DEAC040',
    title: 'Пролет воздушной линии электропередачи',
  },
  {
    type: 'DEAC050',
    title: 'Анкерный пролет воздушной линии электропередачи',
  },
  {
    subCLass2: 'DEB',
    title: 'Здание (сооружение) объектов электрических сетей',
  },
  {
    subCLass2: 'DEC',
    title: 'Кабельная вставка',
  },
  {
    subCLass: 'DF',
    title: 'Объекты газораспределения',
  },
  {
    subCLass2: 'DFA',
    title: 'Здание (сооружение) объектов газораспределительных сетей',
  },
  {
    subCLass2: 'DFB',
    title:
      'Здание (сооружение) объектов обеспечения сжатым и сжиженным газовым топливом',
  },
  {
    subCLass: 'DG',
    title: 'Объекты теплоснабжения и кондиционирования воздуха',
  },
  {
    subCLass2: 'DGA',
    title: 'Здание (сооружение) объектов вентиляции',
  },
  {
    subCLass2: 'DGB',
    title: 'Здание (сооружение) объектов механической очистки газов, воздуха',
  },
  {
    subCLass2: 'DGC',
    title: 'Здание (сооружение) объектов химической очистки газов, воздуха',
  },
  {
    subCLass2: 'DGD',
    title: 'Здание (сооружение) объектов тепловых и холодильных пунктов',
  },
  {
    subCLass2: 'DGE',
    title: 'Здание (сооружение) объектов тепловых сетей',
  },
  {
    subCLass: 'DH',
    title:
      'Объекты вспомогательной инфраструктуры энергетики и электроэнергетики',
  },
  {
    subCLass2: 'DHA',
    title:
      'Здание (сооружение) вспомогательных объектов атомной электрической станции малой мощности стационарного расположения',
  },
  {
    subCLass2: 'DHB',
    title:
      'Здание (сооружение) вспомогательных объектов атомной электрической станции с реактором на быстрых нейтронах',
  },
  {
    subCLass2: 'DHC',
    title: 'Здание (сооружение) вспомогательных объектов ГЭС',
  },
  {
    subCLass2: 'DHD',
    title:
      'Здание (сооружение) вспомогательных объектов обеспечения электроэнергией',
  },
  {
    subCLass2: 'DHF',
    title:
      'Инженерное сооружение по защите промплощадки атомной электрической станции малой мощности стационарного расположения',
  },
  {
    subCLass2: 'DHG',
    title: 'Коллектор общего назначения',
  },
  {
    subCLass2: 'DHH',
    title:
      'Мастерская ремонтно- эксплуатационные паротурбинных конденсационных электрических станций, ТЭЦ, котельных, электрокотельных, дизельных электростанций, газотурбинных электрических станций',
  },
  {
    subCLass2: 'DHJ',
    title:
      'Обеспечивающий объект атомной электрической станции малой мощности стационарного расположения',
  },
  {
    subCLass2: 'DHK',
    title:
      'Обеспечивающий объект атомной электрической станции с водо-водяным энергетическим реактором',
  },
  {
    subCLass2: 'DHL',
    title:
      'Обеспечивающий объект атомной электрической станции с реактором канального типа',
  },
  {
    subCLass2: 'DHM',
    title:
      'Обеспечивающий объект атомной электрической станции с реактором на быстрых нейтронах',
  },
  {
    subCLass2: 'DHN',
    title: 'Общестанционный объект атомных электрических станций',
  },
  {
    subCLass2: 'DHP',
    title:
      'Объект предприятия по производству энергетических атомных реакторов, паровых гидравлических и газовых турбин и турбовспомогательного оборудования',
  },
  {
    class: 'E',
    title: 'Объекты водоснабжения, водоотведения, водоочистки',
  },
  {
    subCLass: 'EA',
    title: 'Объекты водозабора и водоподготовки',
  },
  {
    subCLass2: 'EAA',
    title:
      'Здание (сооружение) объектов хранения и подачи воды (резервуары, насосные станции, напорные башни)',
  },
  {
    subCLass2: 'EAB',
    title: 'Гидротехнические сооружения',
  },
  {
    subCLass2: 'EAC',
    title: 'Здание (сооружение) объектов водоподготовки и очистки воды',
  },
  {
    subCLass2: 'EAD',
    title: 'Сооружение объектов забора воды',
  },
  {
    subCLass: 'EB',
    title: 'Объекты водораспределения',
  },
  {
    subCLass2: 'EBA',
    title: 'Водопровод (сеть водоснабжения)',
  },
  {
    subCLass2: 'EBB',
    title: 'Насосная станция',
  },
  {
    subCLass: 'EC',
    title: 'Объекты очистки сточных, в том числе дренажных, вод',
  },
  {
    subCLass2: 'ECA',
    title: 'Здание (сооружение)объектов накопительной водоочистки',
  },
  {
    subCLass2: 'ECB',
    title: 'Здание (сооружение) объектов водоочистки',
  },
  {
    subCLass2: 'ECC',
    title: 'Сооружение объектов канализации сточных вод',
  },
  {
    subCLass: 'ED',
    title: 'Объекты приема сточных вод',
  },
  {
    subCLass2: 'EDA',
    title: 'Заглубленное сооружение объектов для сбора сточных вод',
  },
  {
    subCLass2: 'EDB',
    title: 'Сооружение объектов сетей канализации',
  },
  {
    subCLass2: 'EDC',
    title: 'Здание (сооружение) насосных станций сбора сточных вод',
  },
  {
    subCLass2: 'EDD',
    title: 'Сооружение пруда-накопителя',
  },
  {
    subCLass2: 'EDE',
    title: 'Сооружение объектов сетей канализации, сети сбора стоков',
  },
  {
    class: 'F',
    title:
      'Объекты сбора, переработки и утилизации отходов, обработка вторичного сырья',
  },
  {
    subCLass: 'FA',
    title: 'Объекты обработки и утилизации неопасных отходов',
  },
  {
    subCLass2: 'FAA',
    title:
      'Здание (сооружение) объектов переработки и утилизации промышленных и бытовых отходов',
  },
  {
    subCLass2: 'FAB',
    title:
      'Здание (сооружение) объектов утилизации отходов животного происхождения',
  },
  {
    subCLass: 'FB',
    title: 'Объекты переработки вторичного сырья',
  },
  {
    subCLass2: 'FBA',
    title:
      'Здание (сооружение) объектов переработки вторичного металлического сырья',
  },
  {
    subCLass2: 'FBB',
    title:
      'Здание (сооружение) объектов переработки вторичного неметаллического сырья',
  },
  {
    subCLass: 'FC',
    title: 'Объекты сбора, обработки и утилизации опасных отходов',
  },
  {
    subCLass2: 'FCA',
    title: 'Здание (сооружение) объектов переработки радиоактивных отходов',
  },
  {
    subCLass2: 'FCB',
    title:
      'Здание (сооружение) объектов хранения отходов атомных электрических станций и опасных отходов',
  },
  {
    class: 'G',
    title: 'Объекты городской среды, охраны природы',
  },
  {
    subCLass: 'GA',
    title: 'Гостиницы, хостелы, мотели',
  },
  {
    subCLass2: 'GAA',
    title: 'Здание гостиницы',
  },
  {
    subCLass2: 'GAB',
    title: 'Здание мотеля',
  },
  {
    subCLass2: 'GAC',
    title: 'Здание хостела',
  },
  {
    subCLass: 'GB',
    title: 'Объекты бытового обслуживания',
  },
  {
    subCLass2: 'GBA',
    title: 'Здание бани (душевой, сауны)',
  },
  {
    subCLass2: 'GBB',
    title: 'Здание (сооружение) объектов похоронного назначения (кладбища)',
  },
  {
    subCLass2: 'GBC',
    title: 'Здание прачечной, химчистки и мелкого ремонта одежды и обуви',
  },
  {
    subCLass2: 'GBD',
    title:
      'Здание (сооружение) объектов бытового обслуживания прочего назначения',
  },
  {
    subCLass: 'GC',
    title: 'Объекты жилой застройки',
  },
  {
    subCLass2: 'GCA',
    title: 'Жилые объекты для постоянного проживания',
  },
  {
    subCLass3: 'GCAA',
    title: 'Жилой дом блокированной застройки',
  },
  {
    subCLass3: 'GCAB',
    title: 'Дом жилой одноквартирный отдельно стоящий',
  },
  {
    subCLass3: 'GCAC',
    title: 'Многоквартирный жилой дом',
  },
  {
    subCLass2: 'GCB',
    title: 'Инженерные сети населенных пунктов',
  },
  {
    subCLass3: 'GCBA',
    title: 'Сеть газораспределения СУГ',
  },
  {
    subCLass3: 'GCBB',
    title: 'Канализационная сеть',
  },
  {
    subCLass3: 'GCBC',
    title: 'Сеть водоотведения',
  },
  {
    subCLass3: 'GCBD',
    title: 'Сеть водоснабжения',
  },
  {
    subCLass3: 'GCBE',
    title: 'Сеть связи',
  },
  {
    subCLass3: 'GCBF',
    title: 'Сеть теплоснабжения',
  },
  {
    subCLass3: 'GCBG',
    title: 'Сеть электроснабжения',
  },
  {
    subCLass2: 'GCC',
    title: 'Специализированные объекты жилого назначения',
  },
  {
    subCLass: 'GD',
    title: 'Объекты общественного питания',
  },
  {
    subCLass2: 'GDA',
    title:
      'Здание объектов общественного питания быстрого обслуживания и на вынос',
  },
  {
    subCLass2: 'GDB',
    title: 'Здание объектов общественного питания длительного посещения',
  },
  {
    subCLass: 'GE',
    title: 'Объекты сохранения растительного и животного мира',
  },
  {
    subCLass2: 'GEA',
    title: 'Объект сохранения животного мира',
  },
  {
    subCLass2: 'GEB',
    title: 'Объект сохранения природы в естественном состоянии',
  },
  {
    subCLass: 'GF',
    title: 'Почтово-логистические объекты',
  },
  {
    subCLass2: 'GFA',
    title: 'Здание объектов почтовой связи',
  },
  {
    subCLass2: 'GFB',
    title: 'Здание объектов служб доставки и транспортно-экспедиционных услуг',
  },
  {
    subCLass: 'GG',
    title: 'Объекты торговли',
  },
  {
    subCLass2: 'GGA',
    title: 'Здание специализированных объектов торговли',
  },
  {
    subCLass2: 'GGB',
    title: 'Универсальные объекты торговли',
  },
  {
    subCLass3: 'GGBA',
    title: 'Здание гастронома',
  },
  {
    subCLass3: 'GGBB',
    title: 'Здание гипермаркета',
  },
  {
    subCLass3: 'GGBC',
    title: 'Здание рынка',
  },
  {
    subCLass3: 'GGBD',
    title: 'Здание торгово-административного комплекса',
  },
  {
    subCLass3: 'GGBE',
    title: 'Здание торгово-делового центра',
  },
  {
    subCLass3: 'GGBF',
    title: 'Здание торгово-развлекательного комплекса',
  },
  {
    subCLass3: 'GGBG',
    title: 'Здание супермаркета',
  },
  {
    subCLass3: 'GGBH',
    title: 'Здание универмага',
  },
  {
    subCLass3: 'GGBJ',
    title: 'Здание универсама',
  },
  {
    class: 'H',
    title: 'Объекты транспорта',
  },
  {
    subCLass: 'HA',
    title: 'Объекты автомобильного транспорта',
  },
  {
    subCLass2: 'HAA',
    title: 'Сооружение автомобильной дороги в границах населённых пунктов',
  },
  {
    subCLass2: 'HAB',
    title: 'Сооружение автомобильной дороги вне населённых пунктов',
  },
  {
    subCLass2: 'HAC',
    title: 'Здание (сооружение) автостоянки',
  },
  {
    subCLass2: 'HAD',
    title: 'Здание (сооружение) автотранспортного предприятия',
  },
  {
    subCLass2: 'HAE',
    title:
      'Здание (сооружение) контрольно-пропускного пункта, терминала автомобильного транспорта',
  },
  {
    subCLass2: 'HAF',
    title: 'Здание (сооружение) автозаправочной станции',
  },
  {
    subCLass2: 'HAG',
    title: 'Здание (сооружение) обустройства автомобильных дорог',
  },
  {
    subCLass2: 'HAH',
    title: 'Здание пассажирского автовокзала (станции)',
  },
  {
    subCLass2: 'HAJ',
    title:
      'Здание (сооружение) профилактория (станции технического обслуживания) автомобилей',
  },
  {
    subCLass2: 'HAK',
    title: 'Сооружение инженерной защиты населения и территорий',
  },
  {
    subCLass2: 'HAL',
    title: 'Здание (сооружение) автомобильных дорог промышленных предприятий',
  },
  {
    subCLass3: 'HALA',
    title: 'Внутриплощадочные автомобильные дороги',
  },
  {
    subCLass3: 'HALB',
    title: 'Межплощадочные автомобильные дороги',
  },
  {
    subCLass: 'HB',
    title: 'Объекты водного транспорта',
  },
  {
    subCLass2: 'HBA',
    title: 'Гидротехническое сооружение для водного транспорта',
  },
  {
    subCLass2: 'HBB',
    title:
      'Здание (сооружение) объектов государственной границы на водном транспорте',
  },
  {
    subCLass2: 'HBC',
    title: 'Здание (сооружение) портовых объектов грузового назначения',
  },
  {
    subCLass2: 'HBD',
    title: 'Здание (сооружение) портовых объектов общего назначения',
  },
  {
    subCLass2: 'HBE',
    title: 'Здание (сооружение) портовых объектов пассажирского назначения',
  },
  {
    subCLass2: 'HBF',
    title: 'Сооружение причала',
  },
  {
    subCLass3: 'HBFA',
    title: 'Сооружение выносных причальных устройств',
  },
  {
    subCLass2: 'HBG',
    title: 'Сооружение системы обеспечения безопасности мореплавания',
  },
  {
    subCLass2: 'HBH',
    title: 'Здание (сооружение) объектов судоремонтного предприятия',
  },
  {
    subCLass2: 'HBJ',
    title: 'Сухопутное сооружение, судоприемник',
  },
  {
    subCLass2: 'HBK',
    title: 'Здание (сооружение) объектов морских терминалов',
  },
  {
    subCLass3: 'HBKA',
    title:
      'Здание (сооружение) объектов терминалов, специализированных для наливных грузов',
  },
  {
    type: 'HBKA010',
    title:
      'Здание (сооружение) объектов терминалов, специализированных для нефти и нефтепродуктов',
  },
  {
    type: 'HBKA020',
    title:
      'Здание (сооружение) объектов терминалов сжиженных углеводородных газов и сжиженного природного газа',
  },
  {
    type: 'HBKA030',
    title:
      'Здание (сооружение) объектов терминалов, специализированных для жидких химических грузов',
  },
  {
    type: 'HBKA040',
    title:
      'Здание (сооружение) объектов терминалов, специализированных для жидких пищевых грузов',
  },
  {
    subCLass3: 'HBKB',
    title: 'Здание (сооружение) объектов терминалов универсального назначения',
  },
  {
    subCLass3: 'HBKC',
    title:
      'Здание (сооружение) объектов терминалов, специализированных для навалочных и насыпных грузов',
  },
  {
    subCLass3: 'HBKD',
    title:
      'Здание (сооружение) объектов терминалов, специализированных для контейнеров',
  },
  {
    subCLass3: 'HBKE',
    title:
      'Здание (сооружение) объектов терминалов, специализированных для накатных грузов',
  },
  {
    subCLass3: 'HBKF',
    title:
      'Здание (сооружение) объектов терминалов, специализированных для судов лихтеровозной системы',
  },
  {
    subCLass3: 'HBKG',
    title: 'Здание (сооружение) объектов терминалов морских паромных переправ',
  },
  {
    subCLass: 'HC',
    title: 'Объекты воздушного транспорта',
  },
  {
    subCLass2: 'HCA',
    title: 'Здание (сооружение) аэродрома (пассажирские аэродромы)',
  },
  {
    subCLass2: 'HCB',
    title: 'Здание (сооружение) аэропорта (пассажирские аэропорты)',
  },
  {
    subCLass2: 'HCC',
    title: 'Здание (сооружение) вертодрома',
  },
  {
    subCLass: 'HD',
    title: 'Объекты городского пассажирского транспорта',
  },
  {
    subCLass2: 'HDA',
    title: 'Объекты городского электрического колесного транспорта',
  },
  {
    subCLass2: 'HDB',
    title: 'Объекты городского электрического рельсового транспорта',
  },
  {
    subCLass: 'HE',
    title: 'Объекты инфраструктуры железнодорожного транспорта',
  },
  {
    subCLass2: 'HEA',
    title: 'Железнодорожный путь',
  },
  {
    subCLass3: 'HEAA',
    title: 'Железнодорожные пути общего пользования',
  },
  {
    subCLass3: 'HEAB',
    title: 'Железнодорожные пути необщего пользования',
  },
  {
    subCLass2: 'HEB',
    title: 'Объекты железнодорожного логистического центра',
  },
  {
    subCLass2: 'HEC',
    title:
      'Объекты пассажирской инфраструктуры железнодорожного транспорта (пассажирское хозяйство)',
  },
  {
    subCLass2: 'HED',
    title: 'Объекты ремонта и обслуживания вагонов (вагонное  хозяйство)',
  },
  {
    subCLass2: 'HEE',
    title:
      'Объекты ремонта и обслуживания локомотивов (локомотивное хозяйство)',
  },
  {
    subCLass2: 'HEF',
    title:
      'Объекты технического обслуживания, диагностики (мониторинга), ремонта железнодорожного пути (путевое хозяйство)',
  },
  {
    subCLass2: 'HEH',
    title:
      'Объекты для производственных и административно-хозяйственных нужд по управлению инфраструктурой железнодорожного транспорта',
  },
  {
    subCLass2: 'HEJ',
    title:
      'Объекты железнодорожного электроснабжения (хозяйство электроснабжения и электрификации)',
  },
  {
    subCLass2: 'HEK',
    title: 'Объекты железнодорожной автоматики и телемеханики (хозяйство ЖАТ)',
  },
  {
    subCLass2: 'HEL',
    title:
      'Объекты железнодорожной электросвязи (хозяйство железнодорожной электросвязи)',
  },
  {
    subCLass2: 'HEM',
    title: 'Объекты защитных железнодорожных сооружений',
  },
  {
    subCLass: 'HF',
    title: 'Объекты магистральных газопроводов',
  },
  {
    subCLass2: 'HFA',
    title: 'Сооружение газопровода',
  },
  {
    subCLass3: 'HFAA',
    title: 'Противоэрозионное, защитное сооружение газопровода',
  },
  {
    subCLass3: 'HFAB',
    title: 'Сооружение технологической связи газопровода',
  },
  {
    subCLass3: 'HFAC',
    title: 'Сооружение электрохимической защиты от коррозии',
  },
  {
    subCLass3: 'HFAD',
    title: 'Сооружение узел  редуцирования газа',
  },
  {
    subCLass3: 'HFAE',
    title: 'Cооружение объектов подземного хранения газа',
  },
  {
    subCLass2: 'HFB',
    title:
      'Сооружение компрессорной станции, объектов приемо-сдаточной инфраструктуры и сопутствующих объектов газопроводов',
  },
  {
    subCLass3: 'HFBA',
    title: 'Площадка  магистральных газопроводов',
  },
  {
    subCLass3: 'HFBB',
    title: 'Сооружение станции охлаждения газа ',
  },
  {
    subCLass3: 'HFBC',
    title: 'Сооружение газоизмерительной станции ',
  },
  {
    subCLass3: 'HFBD',
    title: 'Сооружение дожимной компрессорной станции ',
  },
  {
    subCLass3: 'HFBE',
    title:
      'Здание компрессорного цеха для выполнения очистки, компримирования, охлаждения газа ',
  },
  {
    subCLass3: 'HFBF',
    title:
      'Сооружение узла подключения компрессорной станции к магистральному газопроводу ',
  },
  {
    subCLass3: 'HFBG',
    title:
      'Сооружение конденсатосборника и устройства для ввода метанола газопровода ',
  },
  {
    subCLass3: 'HFBH',
    title: 'Сооружение газораспределительной станции (ГРС, АГРС) газопровода ',
  },
  {
    subCLass: 'HG',
    title: 'Объекты магистральных нефтепроводов (нефтепродуктопроводов)',
  },
  {
    subCLass2: 'HGA',
    title:
      'Сооружение насосной станции, объектов приемо-сдаточной инфраструктуры и сопутствующих объектов нефтепроводов',
  },
  {
    subCLass3: 'HGAA',
    title: 'Пункт подогрева нефти',
  },
  {
    subCLass3: 'HGAB',
    title: 'Приемо-сдаточный пункт',
  },
  {
    subCLass3: 'HGAС',
    title: 'Площадка  магистральных нефтепроводов (нефтепродуктопроводов)',
  },
  {
    subCLass2: 'HGB',
    title: 'Сооружение нефтепровода, нефтепродуктопровода',
  },
  {
    subCLass3: 'HGBA',
    title: 'Технологический участок',
  },
  {
    subCLass3: 'HGBB',
    title: 'Основной технологический трубопровод',
  },
  {
    type: 'HGBB010',
    title: 'Напорный трубопровод',
  },
  {
    type: 'HGBB020',
    title: 'Коллекторы магистральных агрегатов',
  },
  {
    type: 'HGBB030',
    title: 'Коллекторы подпорных агрегатов',
  },
  {
    subCLass3: 'HGBC',
    title: 'Вспомогательный технологический трубопровод',
  },
  {
    subCLass3: 'HGBD',
    title:
      'Трубопроводы обвязки узла пуска и приема средств очистки и диагностирования',
  },
  {
    subCLass2: 'HGC',
    title: 'Резервуары для хранения нефти и нефтепродукта',
  },
  {
    subCLass3: 'HGCA',
    title: 'Резервуар вертикальный стальной',
  },
  {
    subCLass3: 'HGCB',
    title: 'Резервуар вертикальный стальной с понтоном',
  },
  {
    subCLass3: 'HGCC',
    title: 'Резервуар вертикальный стальной с плавающей крышей',
  },
  {
    subCLass3: 'HGCD',
    title:
      'Резервуар вертикальный стальной с алюминиевым понтоном и алюминиевой купольной крышей',
  },
  {
    subCLass3: 'HGCF',
    title: 'Резервуар горизонтальный стальной',
  },
  {
    subCLass3: 'HGCG',
    title: 'Железобетонный резервуар',
  },
  {
    subCLass2: 'HGD',
    title: 'Система сглаживания волн давления',
  },
  {
    subCLass2: 'HGE',
    title: 'Линейная часть магистрального трубопровода',
  },
  {
    subCLass: 'HH',
    title:
      'Объекты магистральных трубопроводов для транспортирования аммиака и других сред',
  },
  {
    subCLass2: 'HHA',
    title:
      'Сооружение насосной станции, объектов приемо-сдаточной инфраструктуры и сопутствующих объектов трубопроводов для транспортирования аммиака и других сред',
  },
  {
    subCLass2: 'HHB',
    title: 'Сооружение трубопровода для транспортировки аммиака и других сред',
  },
  {
    subCLass: 'HJ',
    title:
      'Объекты магистральных трубопроводов СУГ, СПГ, нестабильного конденсата и нестабильного бензина, нефтяного газа и других сжиженных углеводородов (ШФЛУ)',
  },
  {
    subCLass2: 'HJA',
    title:
      'Насосная станция, объекты приемо-сдаточная инфраструктура и сопутствующие объекты трубопроводов СУГ, СПГ и пр.',
  },
  {
    subCLass2: 'HJB',
    title: 'Сооружение трубопровода СУГ, СПГ и пр.',
  },
  {
    subCLass: 'HK',
    title: 'Объекты метрополитена',
  },
  {
    subCLass2: 'HKA',
    title: 'Сооружение перегонного тоннеля',
  },
  {
    subCLass2: 'HKB',
    title: 'Здание (сооружение) станции метрополитена',
  },
  {
    subCLass2: 'HKC',
    title: 'Здание (сооружение) электродепо метрополитена',
  },
  {
    subCLass: 'HL',
    title: 'Системы перевозок, в том числе внеуличный транспорт',
  },
  {
    subCLass2: 'HLA',
    title: 'Сооружение грузовой канатной дороги',
  },
  {
    subCLass2: 'HLB',
    title: 'Сооружение пассажирской канатной дороги',
  },
  {
    subCLass2: 'HLC',
    title: 'Сооружение рельсового транспорта',
  },
  {
    subCLass: 'HM',
    title: 'Транспортные проходы и переходы',
  },
  {
    subCLass2: 'HMA',
    title: 'Мостовое сооружение',
  },
  {
    subCLass3: 'HMAA',
    title: 'Мост',
  },
  {
    subCLass3: 'HMAB',
    title: 'Мостовой переход',
  },
  {
    subCLass3: 'HMAC',
    title: 'Эстакада',
  },
  {
    type: 'HMAC010',
    title: 'Железнодорожная эстакада',
  },
  {
    type: 'HMAC020',
    title: 'Крановая эстакада',
  },
  {
    type: 'HMAC030',
    title: 'Переходной мост',
  },
  {
    type: 'HMAC040',
    title: 'Эстакада мазутопровода',
  },
  {
    subCLass3: 'HMAD',
    title: 'Путепровод',
  },
  {
    subCLass3: 'HMAE',
    title: 'Путепровод тоннельного типа',
  },
  {
    subCLass2: 'HMB',
    title: 'Сооружение тоннеля',
  },
  {
    subCLass2: 'HMC',
    title: 'Подпорное сооружение',
  },
  {
    subCLass: 'HN',
    title: 'Шумозащитное сооружение',
  },
  {
    subCLass: 'HP',
    title: 'Объекты для подъема',
  },
  {
    subCLass2: 'HPA',
    title:
      'Здание (сооружение) объектов предприятий по производству электрических мостовых и козловых кранов',
  },
  {
    subCLass2: 'HPB',
    title:
      'Здание (сооружение) объектов предприятий по производству конвейеров ленточных, скребковых, подвесных грузонесущих, погрузочных устройств для контейнерных грузов, талей (тельферов), эскалаторов и другого подъемно-транспортного оборудования',
  },
  {
    subCLass: 'HQ',
    title: 'Объекты магистральных трубопроводов',
  },
  {
    subCLass2: 'HQA',
    title:
      'Переходы трубопроводов через естественные и искуственные препятствия',
  },
  {
    subCLass3: 'HQAA',
    title: 'Переход магистрального трубопровода через малый водоток, водоем',
  },
  {
    subCLass3: 'HQAB',
    title: 'Подводный переход магистрального трубопровода',
  },
  {
    subCLass3: 'HQAC',
    title: 'Переход трубопровода через автодорогу',
  },
  {
    subCLass3: 'HQAD',
    title: 'Переход трубопровода через железную дорогу',
  },
  {
    subCLass3: 'HQAE',
    title: 'Переход воздушный через малый водоток, водоем',
  },
  {
    subCLass2: 'HQB',
    title: 'Узлы пуска, приема и пропуска средств очистки и диагностирования',
  },
  {
    subCLass3: 'HQBA',
    title: 'Узел камеры пуска средств очистки и диагностирования',
  },
  {
    subCLass3: 'HQBB',
    title: 'Узел камеры приема средств очистки и диагностирования',
  },
  {
    subCLass3: 'HQBC',
    title: 'Узел пропуска средств очистки и диагностирования',
  },
  {
    subCLass2: 'HQC',
    title: 'Проезд вдольтрассовый',
  },
  {
    class: 'J',
    title: 'Объекты хранения',
  },
  {
    subCLass: 'JA',
    title: 'Объекты хранения продукции сельского хозяйства и пищевых продуктов',
  },
  {
    subCLass2: 'JAA',
    title:
      'Объекты хранения продукции сельского хозяйства и пищевых продуктов при температуре окружающей среды',
  },
  {
    subCLass3: 'JAAA',
    title:
      'Здание (сооружение) объектов хранения продукции сельского хозяйства и пищевых продуктов при температуре окружающей среды',
  },
  {
    subCLass3: 'JAAB',
    title:
      'Здание (сооружение) объектов для хранения продукции сельского хозяйства и пищевых продуктов силосного типа',
  },
  {
    subCLass2: 'JAB',
    title:
      'Объекты хранения продукции сельского хозяйства и пищевых продуктов в охлажденном виде',
  },
  {
    subCLass: 'JB',
    title: 'Объекты хранения материалов',
  },
  {
    subCLass2: 'JBA',
    title:
      'Здание (сооружение) объектов хранения горючих жидкостей и жидкого топлива',
  },
  {
    subCLass2: 'JBB',
    title: 'Здание (сооружение) объектов хранения негорючих жидкостей',
  },
  {
    subCLass2: 'JBC',
    title: 'Здание (сооружение) объектов хранения твердого топлива',
  },
  {
    subCLass2: 'JBD',
    title: 'Здание (сооружение) объектов хранения газов',
  },
  {
    subCLass2: 'JBE',
    title: 'Объекты хранения сыпучих материалов',
  },
  {
    subCLass3: 'JBEA',
    title: 'Здание (сооружение) объектов хранения сыпучих материалов',
  },
  {
    subCLass3: 'JBEB',
    title:
      'Здание (сооружение) объектов для хранения сыпучих материалов силосного типа',
  },
  {
    subCLass2: 'JBF',
    title:
      'Здание (сооружение) объектов хранения расфасованных и штучных материалов',
  },
  {
    subCLass2: 'JBG',
    title: 'Здание (сооружение) объектов хранения взрывчатых веществ',
  },
  {
    subCLass2: 'JBH',
    title: 'Здание (сооружение) объектов хранения радиоактивных веществ',
  },
  {
    subCLass2: 'JBJ',
    title: 'Здание (сооружение) объектов хранения токсичных материалов',
  },
  {
    subCLass: 'JC',
    title: 'Объекты хранения оборудования, машин, деталей машин и механизмов',
  },
  {
    subCLass2: 'JCA',
    title: 'Здание (сооружение) объектов хранения готовой продукции',
  },
  {
    subCLass2: 'JCB',
    title: 'Объекты хранения машин',
  },
  {
    subCLass2: 'JCC',
    title:
      'Здание (сооружение) объектов хранения оборудования, деталей машин и механизмов',
  },
  {
    subCLass2: 'JCD',
    title: 'Здание (сооружение) объектов хранения товаров',
  },
  {
    subCLass: 'JD',
    title: 'Объекты хранения отходов',
  },
  {
    subCLass2: 'JDA',
    title: 'Объекты хранения неопасных отходов',
  },
  {
    subCLass3: 'JDAA',
    title:
      'Здание (сооружение) объектов хранения твердых коммунальных и смешанных отходов',
  },
  {
    subCLass3: 'JDAB',
    title: 'Здание (сооружение) объектов хранения промышленных отходов',
  },
  {
    subCLass2: 'JDB',
    title: 'Объекты хранения опасных отходов',
  },
  {
    subCLass3: 'JDBA',
    title: 'Здание (сооружение) объектов хранения биологических отходов',
  },
  {
    subCLass3: 'JDBB',
    title: 'Здание (сооружение) объектов хранения радиоактивных отходов',
  },
  {
    subCLass3: 'JDBC',
    title: 'Здание (сооружение) объектов хранения токсичных отходов',
  },
  {
    class: 'K',
    title: 'Объекты культуры и искусства',
  },
  {
    subCLass: 'KA',
    title: 'Объекты культуры, искусства и истории',
  },
  {
    subCLass2: 'KAA',
    title: 'Библиотеки и архивы',
  },
  {
    subCLass3: 'KAAA',
    title: 'Здание архива',
  },
  {
    subCLass3: 'KAAB',
    title: 'Здание библиотеки',
  },
  {
    subCLass2: 'KAB',
    title: 'Исторические места и здания, памятники культуры',
  },
  {
    subCLass3: 'KABA',
    title: 'Историческое здание',
  },
  {
    subCLass3: 'KABB',
    title: 'Сооружение памятника культуры',
  },
  {
    subCLass2: 'KAC',
    title: 'Здание культурно-досугового объекта',
  },
  {
    subCLass2: 'KAD',
    title: 'Здание культурно-зрелищного объекта',
  },
  {
    subCLass2: 'KAE',
    title: 'Здание культурно-просветительского объекта',
  },
  {
    subCLass: 'KB',
    title:
      'Объекты производства и издания литературных и музыкальных произведений, кино- и видеофильмов, телевизионных программ и звукозаписей',
  },
  {
    subCLass2: 'KBA',
    title: 'Здание издательства',
  },
  {
    subCLass2: 'KBB',
    title: 'Здание студии звукозаписи и издания музыкальных произведений',
  },
  {
    subCLass2: 'KBC',
    title: 'Здание студии производства кинофильмов и телевизионных программ',
  },
  {
    subCLass: 'KC',
    title: 'Объекты религиозного и культового обеспечения',
  },
  {
    subCLass2: 'KCA',
    title:
      'Здание (сооружение) религиозных и культовых объектов образовательного назначения',
  },
  {
    subCLass2: 'KCB',
    title: 'Здание (сооружение) религиозных и культовых объектов, монастырей',
  },
  {
    class: 'L',
    title:
      'Объекты капитального строительства информационно-коммуникационных технологий',
  },
  {
    subCLass: 'LA',
    title: 'Объекты обработки и хранения данных',
  },
  {
    subCLass2: 'LAA',
    title: 'Здание (сооружение) объектов обработки данных',
  },
  {
    subCLass2: 'LAB',
    title: 'Здание (сооружение) объектов организаций хранения данных',
  },
  {
    subCLass: 'LB',
    title: 'Объекты связи на базе беспроводных технологий',
  },
  {
    subCLass2: 'LBA',
    title: 'Здание (сооружение) радиотелецентра',
  },
  {
    subCLass2: 'LBB',
    title: 'Здание (сооружение) объектов для обеспечения связи',
  },
  {
    subCLass3: 'LBBA',
    title: 'Здание (сооружение) земных станций спутниковой связи',
  },
  {
    subCLass: 'LC',
    title: 'Объекты связи на базе проводных технологий',
  },
  {
    subCLass2: 'LCA',
    title: 'Сооружение кабельной линии связи с волоконно-оптическим кабелем',
  },
  {
    subCLass2: 'LCB',
    title: 'Здание (сооружение) объектов проводной связи',
  },
  {
    subCLass2: 'LCC',
    title: 'Здание (сооружение) объектов сетей проводного вещания',
  },
  {
    subCLass2: 'LCD',
    title: 'Здание (сооружение) телефонной (телеграфной) станции',
  },
  {
    subCLass2: 'LCE',
    title: 'Здание (сооружение) центра коммутации',
  },
  {
    class: 'M',
    title:
      'Объекты административно-делового, государственного, муниципального и общественного управления',
  },
  {
    subCLass: 'MA',
    title:
      'Объекты административного управления и бытового обеспечения промышленных предприятий',
  },
  {
    subCLass2: 'MAA',
    title: 'Здание (сооружение) объектов административно­бытовые',
  },
  {
    subCLass2: 'MAB',
    title: 'Здание (сооружение) объектов административно-охранные',
  },
  {
    subCLass: 'MB',
    title: 'Объекты бизнес управления',
  },
  {
    subCLass2: 'MBA',
    title: 'Здание бизнес-центра',
  },
  {
    subCLass2: 'MBB',
    title: 'Здание (сооружение) логистического центра',
  },
  {
    subCLass2: 'MBC',
    title:
      'Здание (сооружение) объектов финансово­кредитных и страховых организаций',
  },
  {
    subCLass: 'MC',
    title:
      'Объекты государственного и муниципального управления общего и социально­экономического назначения',
  },
  {
    subCLass2: 'MCA',
    title:
      'Здание (сооружение) объектов органов законодательной власти и органов местного самоуправления',
  },
  {
    subCLass2: 'MCB',
    title: 'Здание (сооружение) объектов органов исполнительной власти',
  },
  {
    subCLass2: 'MCC',
    title:
      'Здание (сооружение) объектов представительств иностранных государств',
  },
  {
    subCLass2: 'MCD',
    title:
      'Здание (сооружение) объектов представительств Российской Федерации за рубежом',
  },
  {
    class: 'N',
    title:
      'Объекты для осуществления научных исследований, проектирования, изысканий',
  },
  {
    subCLass: 'NA',
    title: 'Объекты для осуществления фундаментальных научных исследований',
  },
  {
    subCLass2: 'NAA',
    title:
      'Здание (сооружение) объектов для осуществления научной деятельности в фундаментальных науках',
  },
  {
    subCLass2: 'NAB',
    title:
      'Здание (сооружение) объектов экспериментальной базы фундаментальной науки',
  },
  {
    subCLass: 'NB',
    title: 'Объекты для проведения инженерных изысканий, проектирования',
  },
  {
    subCLass2: 'NBA',
    title:
      'Здание (сооружение) объектов организаций, осуществляющие изыскательскую деятельность',
  },
  {
    subCLass2: 'NBB',
    title:
      'Здание (сооружение) объектов организаций, осуществляющих проектную деятельность',
  },
  {
    subCLass: 'NC',
    title:
      'Объекты для проведения технических испытаний, прикладных исследований, анализа и сертификации',
  },
  {
    subCLass2: 'NCA',
    title:
      'Здание (сооружение) объектов для осуществления анализа, сертификации, технических испытаний',
  },
  {
    subCLass2: 'NCB',
    title:
      'Здание (сооружение) объектов для осуществления деятельности в сфере прикладных исследований',
  },
  {
    class: 'P',
    title: 'Объекты обеспечения безопасности и правопорядка',
  },
  {
    subCLass: 'PA',
    title: 'Объекты гражданской обороны и защиты населения',
  },
  {
    subCLass2: 'PAA',
    title: 'Здание (сооружение) объектов гражданской обороны',
  },
  {
    subCLass2: 'PAB',
    title: 'Здание (сооружение) объектов защиты населения',
  },
  {
    subCLass: 'PB',
    title: 'Объекты обеспечения безопасности и охраны правопорядка',
  },
  {
    subCLass2: 'PBA',
    title: 'Здание (сооружение) объектов обустройства государственной границы',
  },
  {
    subCLass2: 'PBB',
    title:
      'Здание (сооружение) объектов органов власти, обеспечивающих безопасность и охрану правопорядка',
  },
  {
    subCLass: 'PC',
    title:
      'Объекты обеспечения ликвидации чрезвычайных ситуаций и стихийных бедствий',
  },
  {
    subCLass2: 'PCA',
    title:
      'Здание (сооружение) объектов обеспечения ликвидации чрезвычайных ситуаций',
  },
  {
    subCLass2: 'PCB',
    title: 'Здание (сооружение) объектов пожарной охраны',
  },
  {
    subCLass: 'PD',
    title: 'Объекты органов юстиции и правосудия',
  },
  {
    subCLass2: 'PDA',
    title:
      'Здание (сооружение) объектов инфраструктуры уголовно-исполнительной системы',
  },
  {
    subCLass2: 'PDB',
    title:
      'Здание (сооружение) объектов судебных органов, прокуратуры, адвокатуры',
  },
  {
    class: 'Q',
    title: 'Объекты образования',
  },
  {
    subCLass: 'QA',
    title: 'Объекты общего среднего и дошкольного образования',
  },
  {
    subCLass2: 'QAA',
    title: 'Здание (сооружение) объектов дошкольного образования',
  },
  {
    subCLass2: 'QAB',
    title: 'Здание (сооружение) объектов среднего образования',
  },
  {
    subCLass: 'QB',
    title:
      'Объекты среднего профессионального, высшего и дополнительного образования',
  },
  {
    subCLass2: 'QBA',
    title: 'Здание (сооружение) объектов высшего образования',
  },
  {
    subCLass2: 'QBB',
    title: 'Здание (сооружение) объектов дополнительного образования',
  },
  {
    subCLass2: 'QBC',
    title:
      'Здание (сооружение) объектов среднего профессионального образования',
  },
  {
    class: 'R',
    title: 'Объекты здравоохранения',
  },
  {
    subCLass: 'RA',
    title: 'Объекты лечебного обеспечения',
  },
  {
    subCLass2: 'RAA',
    title: 'Здание больницы общего профиля',
  },
  {
    subCLass2: 'RAB',
    title: 'Здание больницы специализированной',
  },
  {
    subCLass2: 'RAC',
    title: 'Здание (сооружение) диагностической организации',
  },
  {
    subCLass2: 'RAD',
    title: 'Здание диспансера',
  },
  {
    subCLass2: 'RAE',
    title: 'Здание лаборатории медицинской',
  },
  {
    subCLass2: 'RAF',
    title: 'Здание (сооружение) объектов инфраструктуры лечебного обеспечения',
  },
  {
    subCLass2: 'RAG',
    title: 'Здание поликлиники',
  },
  {
    subCLass2: 'RAH',
    title:
      'Здание (сооружение) объектов специализированной лечебно­профилактической организации',
  },
  {
    subCLass2: 'RAJ',
    title: 'Здание станции скорой медицинской помощи, переливания крови',
  },
  {
    subCLass2: 'RAK',
    title: 'Здание центра медицинского',
  },
  {
    subCLass: 'RB',
    title: 'Объекты санаторно-курортного обеспечения',
  },
  {
    subCLass2: 'RBA',
    title: 'Здание (сооружение) объектов курортной инфраструктуры',
  },
  {
    subCLass2: 'RBB',
    title: 'Здание (сооружение) санатория',
  },
  {
    subCLass: 'RC',
    title: 'Объекты санитарно-эпидемиологического обеспечения',
  },
  {
    subCLass2: 'RCA',
    title:
      'Здание (сооружение) объектов санитарно-профилактической организации',
  },
  {
    subCLass2: 'RCB',
    title: 'Здание (сооружение) объектов эпидемиологической организации',
  },
  {
    subCLass: 'RD',
    title: 'Медицинские организации особого типа',
  },
  {
    subCLass2: 'RDA',
    title: 'Здание (сооружение) объектов медицинских экспертных организаций',
  },
  {
    subCLass2: 'RDB',
    title: 'Здание (сооружение) специализированного медицинского бюро',
  },
  {
    subCLass2: 'RDC',
    title: 'Здание (сооружение) объектов медицинской организации особого типа',
  },
  {
    class: 'S',
    title: 'Объекты отдыха и развлечений',
  },
  {
    subCLass: 'SA',
    title: 'Объекты для отдыха с кратковременным проживанием',
  },
  {
    subCLass2: 'SAA',
    title: 'Здание (сооружение) базы отдыха',
  },
  {
    subCLass2: 'SAB',
    title: 'Здание (сооружение) дома отдыха',
  },
  {
    subCLass2: 'SAC',
    title: 'Здание (сооружение) лагеря',
  },
  {
    subCLass: 'SB',
    title: 'Объекты для развлечений и отдыха',
  },
  {
    subCLass2: 'SBA',
    title: 'Здание (сооружение) объектов познавательных развлечений',
  },
  {
    subCLass2: 'SBB',
    title: 'Здание (сооружение) объектов развлечений и отдыха',
  },
  {
    class: 'T',
    title: 'Объекты физической культуры и спорта',
  },
  {
    subCLass: 'TA',
    title: 'Крытые спортивные объекты',
  },
  {
    subCLass2: 'TAA',
    title: 'Крытое сооружение для зимних видов спорта',
  },
  {
    subCLass2: 'TAB',
    title: 'Многофункциональное крытое спортивное сооружение',
  },
  {
    subCLass2: 'TAC',
    title: 'Специализированное крытое спортивное сооружение',
  },
  {
    subCLass2: 'TAD',
    title: 'Сооружение крытого стадиона',
  },
  {
    subCLass: 'TB',
    title: 'Объекты для водных видов спорта',
  },
  {
    subCLass2: 'TBA',
    title: 'Сооружение бассейна',
  },
  {
    subCLass2: 'TBB',
    title: 'Сооружение гребного канала',
  },
  {
    subCLass2: 'TBC',
    title:
      'Здание (сооружение) объектов инфраструктуры для водных видов спорта',
  },
  {
    subCLass: 'TC',
    title: 'Открытые спортивные объекты',
  },
  {
    subCLass2: 'TCA',
    title: 'Открытое сооружение для зимних видов спорта',
  },
  {
    subCLass2: 'TCB',
    title: 'Многофункциональное открытое спортивное сооружение',
  },
  {
    subCLass2: 'TCC',
    title: 'Специализированное открытое спортивное сооружение',
  },
  {
    subCLass2: 'TCD',
    title: 'Сооружение открытого стадиона',
  },
]

const result = []
let classItem = {}
let subClass = {}
let subClass2 = {}
let subClass3 = {}

array.forEach((item, i) => {
  const pattern = { id: i + 1, name: item.title }

  if (item.class) {
    pattern.name = `${item.class} - ${pattern.name}`
    classItem = { ...pattern, children: [] }
    result.push(classItem)
  } else if (item.subCLass) {
    pattern.name = `${item.subCLass} - ${pattern.name}`
    subClass = { ...pattern, children: [] }
    classItem.children.push(subClass)
  } else if (item.subCLass2) {
    pattern.name = `${item.subCLass2} - ${pattern.name}`
    subClass2 = { ...pattern, children: [] }
    subClass.children.push(subClass2)
  } else if (item.subCLass3) {
    pattern.name = `${item.subCLass3} - ${pattern.name}`
    subClass3 = { ...pattern, children: [] }
    subClass2.children.push(subClass3)
  } else {
    subClass3.children.push(pattern)
  }
})

export default result
