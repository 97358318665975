<template>
  <v-card class="mb-6" outlined>
    <v-card-title style="font-size: 16px">{{ title }}</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="search"
        class="mb-4"
        outlined
        dense
        hide-details
        label="Поиск"
        @input="handleSearch"
      />
      <div ref="scroll" class="card-scroll">
        <v-treeview
          ref="tree"
          :items="items"
          :search="search"
          :active.sync="active"
          :open="open"
          return-object
          open-on-click
          activatable
          dense
        >
          <template #label="object">
            <slot name="label" v-bind="object">{{ object.item.name }}</slot>
          </template>
        </v-treeview>
      </div>
      <v-chip
        v-if="active.length"
        small
        class="mt-4"
        @click="showActiveElement"
      >
        {{ active[0].name }}
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'TreeSelect',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    treeVisible: false,
    active: [],
    search: '',
    open: [],
  }),
  computed: {
    flatItems: (vm) => {
      const result = []
      const handler = (array) => {
        array.forEach((item) => {
          if (item.children) handler(item.children)
          result.push(item)
        })
      }
      handler(vm.items)
      return result
    },
    activeChain: (vm) => {
      if (!vm.active.length) return []
      const [active] = vm.active
      const parentsIds = vm.$refs.tree.getParents(active.id)
      return vm.flatItems.filter(({ id }) =>
        [...parentsIds, active.id].includes(id)
      )
    },
  },
  watch: {
    active(value) {
      this.$emit('input', value)
    },
  },
  mounted() {
    if (this.value.length) {
      this.active = this.value
    }
  },
  methods: {
    showActiveElement() {
      this.search = ''
      this.open = this.activeChain
      this.$nextTick(() => {
        const activeEl = this.$refs.tree.$el.querySelector(
          '.v-treeview-node--active'
        )
        this.$refs.scroll.scrollTop = activeEl.offsetTop
      })
    },
    handleSearch: debounce(function () {
      const excludedItems = [...this.$refs.tree.excludedItems]
      this.open =
        this.search.length >= 3 && excludedItems.length
          ? this.flatItems.filter(({ id }) => !excludedItems.includes(id))
          : []

      if (this.active.length && !excludedItems.length) {
        this.showActiveElement()
      }
    }, 400),
  },
}
</script>

<style lang="scss" scoped>
.card-scroll {
  height: 250px;
  overflow-x: auto;
  position: relative;
}
</style>
