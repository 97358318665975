<template>
  <MultiBlocks v-model="model" :items="blocks" field-address-key="Address" />
</template>

<script>
import MultiBlocks from '../components/MultiBlocks'

export default {
  name: 'CustomerTab',
  components: { MultiBlocks },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    model: {},
    blocks: [
      { key: 'IssueAuthor', title: 'Генеральный проектировщик' },
      {
        key: 'ProjectDocumentationAuthors',
        title: 'Иные исполнители проектных работ',
      },
      {
        key: 'FullDocIssueAuthor',
        title: 'Исполнители работ по инженерным изысканиям',
      },
    ],
  }),
  watch: {
    model: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    if (Object.keys(this.value).length) {
      this.model = this.value
    }
  },
}
</script>
